import { createSlice } from '@reduxjs/toolkit'

const initialState = ['Recommended']

export const sortingOptionSlice = createSlice({
  name: 'sortingOptionSlice',
  initialState,
  reducers: {
    setSortingOption: (state, action) => {
      switch (action.payload) {
        case 'RECOMMENDED':
          state = initialState
          return state
        case 'LH':
          state = ['Price (low to high)']
          return state
        case 'HL':
          state = ['Price (high to low)']
          return state
        default:
          return state
      }
    }
  }
})

export const { setSortingOption } = sortingOptionSlice.actions

export default sortingOptionSlice.reducer
