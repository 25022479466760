import React, { useEffect, useState, useRef, lazy } from 'react'
import NoSSR from '../../components/NoSSR'
import { connect } from 'react-redux'
import { setOpened } from '@redux/actions'
import { setSearchPickupLocation } from '@redux/slices'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import API from '../../services/API'
import { Helmet } from 'react-helmet'
import { seoDataMap, idDataMap, LocDataMap, dataMap } from '../../util/dynamicLandingPageData'

import Loadable from '@base/loadable'
const DownloadAppBanner = Loadable(lazy(() => import('@components/DownloadAppBanner')))
const GoogleMapComponent = Loadable(lazy(() => import('@components/DynamicLandingPages/GoogleMapComponent')))
const DynamicLandingPageFAQs = Loadable(lazy(() => import('@components/DynamicLandingPages/DynamicLandingPageFAQs')))
const SkipTheLine = Loadable(lazy(() => import('@components/DynamicLandingPages/SkipTheLine')))
const GoodToKnow = Loadable(lazy(() => import('@components/DynamicLandingPages/GoodToKnow')))
const SearchBar = Loadable(lazy(() => import('@components/DynamicLandingPages/SearchBar')))
const PopularCompanies = Loadable(lazy(() => import('@components/DynamicLandingPages/PopularCompanies')))
const BottomLinks = Loadable(lazy(() => import('@components/DynamicLandingPages/BottomLinks')))
const FeatureCards = Loadable(lazy(() => import('@components/HomePage/FeatureCards')))
const DefaultSeeCarRentalsButton = Loadable(
  lazy(() =>
    import('@components/DynamicLandingPages/SkipTheLine').then((module) => ({
      default: module.DefaultSeeCarRentalsButton
    }))
  )
)

const DynamicLandingPageContainer = styled.div`
  width: 100vw;
  height: fit-content;
  background-color: white;
  padding: 0;
  padding-bottom: 50px;
`

const StyledDownloadAppBanner = styled(DownloadAppBanner)`
  @media only screen and (max-width: 576px) {
    display: none;
  }
`

const MobileSeeCarRentalsButton = styled(DefaultSeeCarRentalsButton)`
  width: 360px;
  max-width: 90vw;
  margin: 0 auto;
  margin-top: ${(props) => (props.setMarginTop ? '-80px' : '80px')};
  margin-bottom: ${(props) => (props.setMarginTop ? '100px' : '40px')};

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`

const DesktopSeeCarRentalsButton = styled(DefaultSeeCarRentalsButton)`
  width: 360px;
  max-width: 90vw;
  margin: 100px auto;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const GoogleMapWrapper = styled.div`
  min-height: 50vh;
  min-width: 60vw;
  margin: 50px auto;

  @media only screen and (max-width: 576px) {
    width: 100vw;
  }
`

const FallbackComponent = styled.div`
  background-color: #eff1f8;
  height: 50vh;
  width: 60vw;
  margin: 50px auto;
  border-radius: 16px;

  animation-name: colorChange;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes colorChange {
    0% {
      background-color: #eff1f8;
    }
    50% {
      background-color: #c1c3c9;
    }
    100% {
      background-color: #eff1f8;
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    width: 90vw;
    height: 45vh;
    margin-top: 25px;
    margin-bottom: 80px;
  }

  @media only screen and (max-width: 576px) {
    width: 100vw;
    margin-top: 25px;
    margin-bottom: 80px;
    border-radius: 0px;
  }
`

const GoogleMapHeader = styled.h1`
  color: #525266;
  font-weight: 700;
  font-size: 35px;
  margin: 20px auto;
  margin-bottom: 50px;
  text-align: center;
  font-family: Montserrat;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 30px;
    width: 95vw;
    margin: 0 auto;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    font-size: 23px;
    text-align: left;
    width: 95vw;
    margin: 0 auto;
  }
`

function DynamicPages({ setOpened, history, searchArgs, setSearchPickupLocation, location }) {
  const [supplierInfo, setSupplierInfo] = useState({ count: null, urls: [] })
  const [goodToKnowData, setGoodToKnowData] = useState([])
  const [isImported, setIsImported] = useState(false)
  const scrollRef = useRef()
  const URL = location.pathname
  const pageTitle = seoDataMap[URL].title
  const pageDescription = seoDataMap[URL].description
  const pageImage = dataMap[URL] ? dataMap[URL].url : ''
  const isItaly = dataMap[URL].isItaly ? dataMap[URL].isItaly : false
  const memorial = dataMap[URL].memorial ? dataMap[URL].memorial : false

  const scrollToTop = (e) => {
    e.preventDefault()

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const locID = idDataMap[window.location.pathname].id
      const response = await API.getLandingInfo(locID)

      setGoodToKnowData(response.goodToKnow || [])
      setSupplierInfo({ count: response.supplierCount, urls: response.suppliers })
      return
    }

    setSearchPickupLocation(LocDataMap[URL])

    fetchData()
  }, [])

  const scrollDetector = () => {
    if (!isImported) {
      const scrollCheckerDiv = scrollRef.current

      if (scrollCheckerDiv) {
        const scrollCheckerDivTopOffsetTop = scrollCheckerDiv.offsetTop
        const userScrolled = document.documentElement.scrollTop + window.innerHeight

        if (userScrolled >= scrollCheckerDivTopOffsetTop) {
          setIsImported(true)
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollDetector)
  }, [])

  useEffect(() => {
    if (isImported) window.removeEventListener('scroll', scrollDetector)

    return () => {
      setOpened()
    }
  }, [isImported])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='description' content={pageDescription} />
        <link rel='canonical' href={`https://rentcarla.com${URL}`} />
        {/* <!-- preload LCP image --> */}
        <link rel='preload' fetchpriority='high' as='image' href={pageImage} type='image/webp' />
      </Helmet>
      <NoSSR>
        <DynamicLandingPageContainer>
          <h1 style={{ display: 'none' }}>
            Discover the car rental opportunity you're looking for at Carla! Book now on our website for reliable and
            comfortable travels, and explore our competitive prices
          </h1>
          <SearchBar
            italy={isItaly}
            bg={dataMap[URL].url}
            history={history}
            spring={dataMap[URL].spring ? true : false}
            locName={dataMap[URL].name}
          />
          <PopularCompanies
            memorialDay={memorial}
            italy={isItaly}
            loc={dataMap[URL].name}
            count={supplierInfo.count}
            data={supplierInfo.urls}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <FeatureCards
            memorialDay={memorial}
            italy={isItaly}
            data={dataMap[URL]}
            isPageDynamic={true}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <MobileSeeCarRentalsButton setMarginTop={true} onClick={scrollToTop}>
            {dataMap[URL].spring
              ? 'See Rental Cars'
              : isItaly
                ? 'Cerca in Italia'
                : `See Rental Cars In ${dataMap[URL].name}`}
          </MobileSeeCarRentalsButton>
          <StyledDownloadAppBanner />
          <SkipTheLine
            italy={isItaly}
            scrollToTop={scrollToTop}
            loc={dataMap[URL].name}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <div id='scroll-check-div' ref={scrollRef} />
          <GoogleMapHeader>
            {dataMap[URL].spring
              ? 'Car Hire Locations in or near London'
              : isItaly
                ? 'Località di noleggio auto a o vicino a Roma.'
                : `Car Rental Locations in or near ${dataMap[URL].name}`}
          </GoogleMapHeader>
          {/* TODO: replace google map */}
          {/* <GoogleMapWrapper>
            {isImported && (
              <Suspense fallback={<FallbackComponent />}>
                <GoogleMapComponent loc={dataMap[URL].name} searchArgs={searchArgs} />
              </Suspense>
            )}
          </GoogleMapWrapper> */}
          <GoodToKnow
            italy={isItaly}
            data={goodToKnowData}
            loc={dataMap[URL].name}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <DynamicLandingPageFAQs
            memorialDay={memorial}
            italy={isItaly}
            imageUrl={dataMap[URL].faqImageUrl}
            loc={dataMap[URL].name}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <BottomLinks
            italy={isItaly}
            memorialDay={memorial}
            scrollToTop={scrollToTop}
            spring={dataMap[URL].spring ? dataMap[URL].spring : false}
          />
          <DesktopSeeCarRentalsButton onClick={scrollToTop}>
            {dataMap[URL].spring
              ? 'See Rental Cars'
              : isItaly
                ? 'Noleggio Auto in Italia'
                : `See Rental Cars In ${dataMap[URL].name}`}
          </DesktopSeeCarRentalsButton>
          <MobileSeeCarRentalsButton setMarginTop={false} onClick={scrollToTop}>
            {dataMap[URL].spring
              ? 'See Rental Cars'
              : isItaly
                ? 'Noleggio Auto in Italia'
                : `See Rental Cars In ${dataMap[URL].name}`}
          </MobileSeeCarRentalsButton>
        </DynamicLandingPageContainer>
      </NoSSR>
    </>
  )
}

export default connect(
  (state) => ({
    searchArgs: state.searchArgs
  }),
  { setOpened, setSearchPickupLocation }
)(DynamicPages)
