import { format } from 'date-fns'

import rawArticles from './data.js'

export function escapeHtml(unsafe) {
  return unsafe.split('"').join('&quot;')
}

export function formatBlogDate(date) {
  return format(date, 'EEE LLL dd yyyy')
}

const blog = {
  // add amp-img tag to image objects on article

  formatAmpContent(strings, ...images) {
    const result = []

    for (const i in strings) {
      result.push(strings[i])
      if (images[i]) {
        const { filename, width, height, alt } = images[i]

        const webpFilename = filename.replace('.' + filename.split('.')[1], '.webp')
        const html = `<amp-img
          src="https://s3-eu-west-1.amazonaws.com/carla-blog-images-webp/${webpFilename}"
          width="${width}" height="${height}"
          alt="${alt}"
          layout="responsive"
        />`
        result.push(html)
      }
    }
    return result.join('')
  },

  // add img tag to image objects on article
  formatCanonicalContent: function (strings, ...images) {
    const result = []
    for (const i in strings) {
      result.push(strings[i])
      if (images[i]) {
        const { filename, alt, width, height } = images[i]

        const webpFilename = filename.replace('.' + filename.split('.')[1], '.webp')
        const html = `<img width=${width} height=${height} src="https://s3-eu-west-1.amazonaws.com/carla-blog-images-webp/${webpFilename}" alt="${alt}"/>`
        result.push(html)
      }
    }
    return result.join('')
  },

  /**
   * get articles formatting for amp or canonical
   * @param formatContent {function} handles template literals
   */
  getArticleMap() {
    const articles = {}

    for (const slug in rawArticles) {
      const rawArticle = rawArticles[slug]
      const webpImage = rawArticle.img.replace('.' + rawArticle.img.split('.')[1], '.webp')
      articles[slug] = {
        ...rawArticle,
        slug,
        ampLink: `/amp/blog/${slug}.html`,
        canonicalLink: `/blog/${slug}.html`,
        ampUrl: `https://rentcarla.com/amp/blog/${slug}.html`,
        canonicalUrl: `https://rentcarla.com/blog/${slug}.html`,
        imageUrl: `https://s3-eu-west-1.amazonaws.com/carla-blog-images-webp/${webpImage}`,
        escapedTitle: escapeHtml(rawArticle.title),
        datePublishedFormatted: formatBlogDate(new Date(rawArticle.datePublished))
      }
    }

    return articles
  },

  /**
   * @param article {Article}
   * @returns {{recent: Article[], other: Article[]}}
   */
  getOtherArticles(article) {
    const articles = Object.values(this.getArticleMap()).filter((a) => a.slug !== article.slug)

    return {
      recent: articles.slice(0, 3),
      other: articles.slice(3, articles.length)
    }
  },

  getOrganizationStructure() {
    return `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name" : "Carla Car Rental",
      "url": "https://rentcarla.com",
      "logo": "https://rentcarla.com/images/logo.png",
      "sameAs":[
        "https://www.facebook.com/rentcarla/",
        "https://twitter.com/RentCarla",
        "https://www.instagram.com/rentcarla/",
        "https://www.linkedin.com/company/carla-car-rental/",
        "https://www.youtube.com/@CarlaCarRental"
      ],
      "contactPoint":[
        {
          "@type":"ContactPoint",
          "email":"carla@rentcarla.com",
          "contactType":"customer service"
        },
        {
          "@type":"ContactPoint",
          "email":"partnership@rentcarla.com",
          "contactType":"partnership"
        },
        {
          "@type":"ContactPoint",
          "email":"careers@rentcarla.com",
          "contactType":"careers"
        }
      ]
    }
    `
  },

  getWebsiteStructure() {
    return `
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name" : "Carla Car Rental",
        "url": "https://rentcarla.com"
      }
    `
  },

  getBreadCrumbStructure(article, isAmp) {
    return `
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://rentcarla.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Blog",
          "item": "https://rentcarla.com${isAmp ? '/amp/blog' : '/blog'}"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "${article.escapedTitle}",
          "item": "${isAmp ? article.ampUrl : article.canonicalUrl}"
        }]
      }
    `
  },

  getArticleStructure(article, isAmp) {
    return `
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "headline": "${article.escapedTitle}",
        "description": "${article.description}",
        "articleBody": "${article.body ? article.body.replace(/"/g, '\\"') : ''}",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${article.canonicalUrl}"
        },
        "image": {
          "@type": "ImageObject",
          "url": "${article.imageUrl}",
          "width": ${article.imageWidth},
          "height": ${article.imageHeight}
        },
        "datePublished": "${article.datePublished}",
        "dateModified": "${article.dateModified}",
        "author": {
          "@type": "Person",
          "name": "Umut Ates"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Carla Car Rental",
          "url": "https://rentcarla.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://rentcarla.com/images/amp-logo.png",
            "width": 250,
            "height": 250
          }
        }
      }
    `
  },

  compareArticleCarousel(a1, a2) {
    return (a1.order || 999) - (a2.order || 999)
  },

  getArticleListStructure(articles) {
    return `
      {
        "@context":"https://schema.org",
        "@type":"ItemList",
        "itemListElement":[
          ${[...articles]
            .sort(this.compareArticleCarousel)
            .map(
              (article, i) => `
            {
              "@type": "ListItem",
              "position": ${i + 1},
              "url": "${article.canonicalUrl}"
            }
          `
            )
            .join(',')}
        ]
      }
    `
  }
}

export default blog
