import { createSlice } from '@reduxjs/toolkit'

const initialState = []

// TODO: set without used???
export const extrasSelectedSlice = createSlice({
  name: 'extrasSelectedSlice',
  initialState,
  reducers: {
    setExtrasSelected: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setExtrasSelected } = extrasSelectedSlice.actions

export default extrasSelectedSlice.reducer
