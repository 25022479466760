import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nearMe: false // whether "near-me locations" are enabled or not (default: disabled)
}

/* OPTIMIZE A/B TEST (id: experiment id, value: undefined, 0, 1, 2...) */
const productionId = '46IvC2Z1QByqGAxtarcVxw'
const devId = 'xz04dwAZQLubm-SCqFLDyQ'

export const optimizeSlice = createSlice({
  name: 'optimizeSlice',
  initialState,
  reducers: {
    setOptimizeVariant: (state, action) => {
      if (action.id === productionId || action.id === devId) {
        state.nearMe = '1'
      }
      throw new Error(`experiment id ${action.id} is not defined`)
    }
  }
})

export const { setOptimizeVariant } = optimizeSlice.actions

export default optimizeSlice.reducer
