import React from 'react'
import { themeGet } from 'styled-system'
import { FlexEl, LinkEl } from '../components/layout'
import styled from 'styled-components'
import { theme } from '../styles'

import Loadable from '@base/loadable'
const Menu = Loadable(React.lazy(() => import('react-burger-menu/lib/menus/slide')))

export const LogoLink = styled(LinkEl)`
  min-width: 70px;
  width: 70px;
  background-color: ${themeGet('colors.none')};
  path {
    fill: ${themeGet('colors.colorPrimary')};
  }
`

export const NavbarContainer = styled('nav')`
  display: flex;

  justify-content: flex-start;
  align-items: center;
  height: 80px;

  .active::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition:
      opacity 0.1s linear,
      width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }
`

export const MobileNavbar = styled('nav')`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000 !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 4px 15px;
  background: #fff !important;

  .active::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition:
      opacity 0.1s linear,
      width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }

  a,
  li.MuiListItem-root > * {
    color: var(--textBlack) !important;
  }
  #nav-icon1 span {
    background: ${themeGet('colors.textBlack')};
  }
`

export const MobileNavBarContainer = styled('ul')`
  .active::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition:
      opacity 0.1s linear,
      width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }
`

// Menu Item
export const NavLink = styled(LinkEl)<{ $isMobile?: boolean }>`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  outline-style: none;
  text-decoration: none;
  max-height: 55px;
  position: relative;
  transform: transition 0.1s ease-in-out;
  color: ${({ $isMobile }) => (!$isMobile ? '#fff' : '#525266')};

  &::after {
    display: block;
    content: '';
    width: 0px;
    opacity: 0;
    transition:
      opacity 0.1s linear,
      width 0.3s;
  }

  &:hover::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 100%;
    position: absolute;
    content: '';
    transition:
      opacity 0.1s linear,
      width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }

  &:focus::after {
    height: 11px;
    display: block;
    opacity: 1;
    width: 50%;
    position: absolute;
    content: '';
    transition:
      opacity 0.1s linear,
      width 0.3s;
    border-bottom: 8px solid ${themeGet('colors.colorPrimary')};
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    font-size: 16px;
  }
`

export const OutlineButton = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;
  height: 55px;
  border-radius: 12px;
  font-weight: bold;
  &:hover:after {
    display: none;
  }
  &:focus:after {
    display: none;
  }
`

export const SimpleButton = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 55px;
  font-weight: bold;
`

export const StyledMenu = styled(Menu)`
  width: 100%;
  margin-top: ${theme.navbar.height[0]}px;
  padding-top: 10vh;
  background-color: white;
  text-align: center;
  font-size: 30px;
  position: relative;
  z-index: 1000 !important;

  &::before {
    position: absolute;
    top: -10vh;
    left: 0px;
    content: '';
    width: 100%;
    height: 10vh;
    background: white;
  }
`

export const NavbarWrapper = styled('div')`
  position: relative;
  z-index: 1000 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 0 16px;
`

export const MobileNavbarWrapper = styled('div')`
  position: relative;
  display: flex;
  z-index: 1000 !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const NavTagWrapper = styled(FlexEl).attrs({
  as: 'nav'
})``

const FlexElAsUl = styled(FlexEl).attrs({
  as: 'ul'
})``
export const UlTagWrapper = styled((props) => <FlexElAsUl {...props} />)`
  list-style: none;
`

export const HeaderStyled = styled.header<{ $isPinned: boolean }>`
  background: ${(p) => (p.$isPinned ? '#fff' : 'transparent')};
  height: 80px;
  position: sticky;
  z-index: 1001;
  top: -1px;
  box-shadow: ${(p) => (p.$isPinned ? '0 2px 6px rgba(0, 0, 0, 0.1)' : 'none')};
  a,
  li.MuiListItem-root > * {
    color: ${(p) => (p.$isPinned ? 'var(--textBlack)' : '#fff')};
  }
  #nav-icon1 span {
    background: ${(p) => (p.$isPinned ? 'var(--textBlack)' : '#fff')};
  }
`
