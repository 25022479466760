import debug from 'debug'
import API from './API'
import SessionManager from './SessionManager'
import { format } from 'date-fns'

const log = debug('carla:events')

const sendEvent = async (eventType, searchId, productHash) => {
  return await API.sendEvent({
    eventType,
    sessionId: SessionManager.getSessionId(),
    deviceId: SessionManager.getCarlinDeviceId(),
    timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    searchId: searchId,
    productId: productHash,
    appLanguage: 'en'
  })
}

const EventSender = {
  async sessionStarted() {
    log('session started')
    return await sendEvent('session_started')
  },

  async sessionEnded() {
    log('session ended')
    return await sendEvent('session_ended')
  },

  async searched(searchArgs) {
    log('car searched', searchArgs)
    return await sendEvent('searched')
  },

  async catalogSeen(carSearchResponse) {
    log('catalog seen', carSearchResponse)
    return await sendEvent('catalog_seen', carSearchResponse.searchId, null)
  },

  async carSelected(carRentalProduct) {
    log('car selected', carRentalProduct)
    return await sendEvent('car_selected', carRentalProduct.searchId, carRentalProduct.productHash)
  },

  async reservationStarted(xo1Details) {
    log('reservation_started', xo1Details)
    return await sendEvent('reservation_started', xo1Details.product.searchId, xo1Details.product.productHash)
  },

  async reservationCompleted(reservation, carRentalProduct) {
    log('reservation ended', reservation)
    return await sendEvent('reservation_completed', carRentalProduct.searchId, reservation.rentalProduct.productHash)
  }
}

export default EventSender
