import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Link } from 'react-router-dom'
import { FaInstagram, FaYoutube } from 'react-icons/fa'
import { El, FlexEl, LinkEl } from '../components/layout'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useScreen } from '../components/common/Screens'
import { useHiddenByLang } from '@base/hooks/useHiddenByLang'

const FooterLink = styled(LinkEl)`
  color: #ffffff;
  line-height: 1.2rem;
  font-size: 14px;
  padding: 15px 15px;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  white-space: nowrap;
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    padding: 8px 15px;
    line-height: 1.2rem;
  }
  &:hover,
  &:focus {
    color: ${themeGet('colors.orange.8')};
  }
  &:active {
    color: ${themeGet('colors.orange.9')};
  }
`

const FooterIconLink = styled(FooterLink)`
  font-size: 22px;
  display: flex;
  flex-wrap: wrap;
  //width: 7%;
  line-height: 24px;
`

const FooterContainer = styled.footer`
  &:focus {
    outline: none;
  }
  min-height: ${themeGet('footer.height')}px;
  overflow: hidden;
  //background-color: ${themeGet('colors.colorPrimary')};
  background-color: #223567;
`

const Column = styled(El)`
  display: flex;
  line-height: 5px;
  color: #ffffff;
  font-size: 14px;
  flex-wrap: wrap;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  flex-direction: column;
  text-align: center;
`

const FooterContent = styled(FlexEl)`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; // @todo
  min-width: 10px;
  max-width: 1200px;
`

// TODO: typeof window !== 'undefined'
function Footer({ showFooter = true, ...rest }) {
  const language = useSelector((state) => state.user.language)
  const { t } = useTranslation()
  const { isMobileScreen } = useScreen()
  const hiddenByLang = useHiddenByLang()

  if (!showFooter) return null
  if (isMobileScreen && window.location.pathname.includes('my-reservation')) return null // TODO: Couldn't make hideFooter to word. Refactor needed
  if (typeof window !== 'undefined' && window.location?.pathname?.includes('availableAreas')) return null

  return (
    <FooterContainer {...rest}>
      <FooterContent>
        <Column>
          <div>
            <p>
              <b>
                <strong>Carla</strong>
              </b>
            </p>
          </div>
          {!hiddenByLang && (
            <FooterLink as={Link} to='/blog'>
              Blog
            </FooterLink>
          )}
          <FooterLink as={Link} to='/contact'>
            {t('homePage:contactUs')}
          </FooterLink>
          <FooterLink as={Link} to='/terms-conditions'>
            {t('homePage:footer:terms')}
          </FooterLink>
          <FooterLink as={Link} to='/privacy-policy'>
            {t('homePage:footer:privacy')}
          </FooterLink>
          {!hiddenByLang && (
            <FooterLink as={Link} to='/blog/carla-careers.html'>
              Career
            </FooterLink>
          )}
          {!hiddenByLang && (
            <FooterLink as={Link} to='/sitemap'>
              Site Map
            </FooterLink>
          )}
        </Column>
        {!hiddenByLang && (
          <Column>
            <div>
              <p>
                <b>
                  <strong>Locations</strong>
                </b>
              </p>
            </div>
            <FooterLink as={Link} to='/orlando-car-rental'>
              Orlando Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/miami-car-rental'>
              Miami Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/los-angeles-car-rental'>
              Los Angeles Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/fort-lauderdale-car-rental'>
              Fort Lauderdale Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/tampa-car-rental'>
              Tampa Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/paris-car-rental'>
              Paris Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/las-vegas-car-rental'>
              Las Vegas Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/new-york-city-car-rental'>
              New York City Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/san-francisco-car-rental'>
              San Francisco Car Rental
            </FooterLink>
          </Column>
        )}
        {!hiddenByLang && (
          <Column>
            <div>
              <p>
                <b>
                  <strong>Explore</strong>
                </b>
              </p>
            </div>
            <FooterLink as={Link} to='/last-minute-car-rental'>
              Last Minute Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/blog/car-rental-under-25-usa.html'>
              Under 25 Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/blog/rent-a-car-with-a-debit-card.html'>
              Debit Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/blog/can-i-pay-for-my-trip-over-time.html'>
              Buy Now Pay Later
            </FooterLink>
            <FooterLink as={Link} to='/blog/first-time.html'>
              First Time Car Rental
            </FooterLink>
            <FooterLink as={Link} to='/blog/partnership-with-carla.html'>
              Partner With Us
            </FooterLink>
            <FooterLink as={Link} to='/under25help'>
              Under 25 Help Line
            </FooterLink>
            <FooterLink
              as={Link}
              to='/blog/drive-for-amazon-flex-instacart-doordash-uber-eats-and-lyft-with-carla.html'
            >
              Uber And Lyft Car Rental
            </FooterLink>
          </Column>
        )}
      </FooterContent>
      <FooterContent>
        <FooterIconLink href='https://www.instagram.com/rentcarla/' target='_blank' rel='noopener noreferrer me'>
          <FaInstagram />
        </FooterIconLink>
        <FooterIconLink href='https://www.youtube.com/@CarlaCarRental' target='_blank' rel='noopener noreferrer me'>
          <FaYoutube />
        </FooterIconLink>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
