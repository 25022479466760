import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isActive: false,
  redirectURL: ''
}

export const skyScannerSlice = createSlice({
  name: 'skyScannerSlice',
  initialState,
  reducers: {
    setSkyScanner: (state, action) => {
      state.isActive = action.payload
    },
    setRedirectURL: (state, action) => {
      state.redirectURL = action.payload
    }
  }
})

export const { setSkyScanner, setRedirectURL } = skyScannerSlice.actions

export default skyScannerSlice.reducer
