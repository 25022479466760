import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import Cookies from 'js-cookie'
import useCurrentLang from '../hooks/useCurrentLang'
import GA from '../services/GA'

const utmSource = Cookies.get('utm_source')

// Create a GrowthBook instance
export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-MFYSfZGfr581Ypv',
  trackingCallback: (experiment, result) => {
    GA.viewExperiments(experiment, result)
  }
})

growthbook.init()

export function GrowthBookRootProvider({ children }) {
  const lang = useCurrentLang()
  const { carlinDeviceId, country } = useSelector(state => state.user)

  useEffect(() => {
    growthbook.setAttributes({
      id: carlinDeviceId,
      utmSource: utmSource,
      lang: lang,
      appLanguage: 'en',
      country
    })
  }, [carlinDeviceId, lang, country])

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}
