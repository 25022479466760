import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const xo1DetailsSlice = createSlice({
  name: 'xo1DetailsSlice',
  initialState,
  reducers: {
    setXO1Details: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setXO1Details } = xo1DetailsSlice.actions

export default xo1DetailsSlice.reducer
