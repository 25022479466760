import * as _ from 'lodash-es'

const ccRegex = {
  // either start with the numbers 51-55 or with the numbers 2221-2720. all have 16 digits
  mastercard: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/,
  // start with a 4. New cards have 16 digits. Old cards have 13.
  visa: /^4[0-9]?/,
  // start with 34 or 37 and have 15 digits.
  amex: /^3[47]/,
  // begin with 300-305, 36-38. All have 14 digits
  diners: /^3(?:0[0-5]|[68])/,
  // begin with 6011 or 65. All have 16 digits.
  discover: /^6(?:011|5)/,
  // begin with 2131 or 1800 have 15 digits. begin with 35 have 16 digits.
  jcb: /^(?:2131|1800|35)/
}

const ccMask = {
  default: '9999 9999 9999 9999',
  amex: '9999 999999 99999',
  diners: '9999 9999 9999 99'
}

const ccIcons = {
  mastercard: 'card-master',
  visa: 'card-visa',
  amex: 'card-amex',
  diners: 'card-diners',
  discover: 'card-discover',
  jcb: 'card-jcb',
  unknown: 'card-unknown'
}

const currencySymbols = {
  AED: 'د.إ',
  ANG: 'ƒ',
  ARS: '$',
  AUD: 'A$',
  BRL: 'R$',
  BSD: 'B$',
  CAD: '$',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CZK: 'Kč',
  DKK: 'kr',
  EUR: '€',
  FJD: 'FJ$',
  GBP: '£',
  GHS: 'GH₵',
  GTQ: 'Q',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JMD: 'J$',
  JPY: '¥',
  KRW: '₩',
  LKR: '₨',
  MAD: '.د.م',
  MMK: 'K',
  MXN: 'MX$',
  MYR: 'RM',
  NOK: 'kr',
  NZD: '$',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  RON: 'lei',
  RSD: 'RSD',
  RUB: 'руб',
  SEK: 'kr',
  SGD: 'S$',
  THB: '฿',
  TND: 'DT',
  TRY: '₺',
  TTD: '$',
  TWD: 'NT$',
  USD: '$',
  VEF: 'Bs',
  VND: '₫',
  XAF: 'FCFA',
  XCD: '$',
  XPF: 'F',
  ZAR: 'R'
}

const money = {
  /**
   * get mask for react-text-mask
   * @returns {array}
   */
  getCCMask(ccType) {
    return ccMask[ccType] || ccMask.default
  },

  /**
   * get react fontawesome icon for cc
   * @returns {Component}
   */
  getCCIcon(ccType) {
    return ccIcons[ccType] || 'card-unknown'
  },

  /**
   * get credit card type
   * @returns {string|null}
   */
  getCCType(number) {
    for (let ccType in ccRegex) {
      if (ccRegex[ccType].test(number)) return ccType
    }
    return null
  },

  /**
   * @param amount {string|number} 123.456
   * @param currency {string} USD|TRY...
   * @returns {string} $123.45
   */
  formatMoney(amount, currency) {
    const amountStr = _.round(amount, 2)
    let currencyStr = ''
    if (currency) currencyStr = currencySymbols[currency] || currency
    return `${currencyStr}${amountStr}`
  },
  /**
   * @param amount {string|number} 123.456
   * @param currency {string} USD|TRY...
   * @returns {string} $123
   */
  formatMoneyFloored(amount, currency) {
    const amountStr = _.floor(amount)
    let currencyStr = ''
    if (currency) currencyStr = currencySymbols[currency] || currency
    return `${currencyStr}${amountStr}`
  },

  formatCurrency(currency) {
    return `${currencySymbols[currency]}`
  },

  parseDiscount(priceText) {
    // TODO: Backend should send price and discount accordingly or design should style accordingly
    return priceText.includes('(') ? priceText.match(/\(([^)]+)\)/)[1] : ''
  },

  parsePriceText(priceText) {
    // TODO: Backend should send price and discount accordingly or design should style accordingly
    return priceText.includes('(') ? priceText.match(/.+(?= \()/)[0] : priceText
  },

  parseBasePriceText(basePriceText) {
    return basePriceText?.match(/.\d+.*/)[0]
  },

  formatMoneySignPrefix(amount, currency, round = 0) {
    const signs = { 1: '+', '-1': '-', 0: '' }
    const sign = signs[Math.sign(amount)]
    return `${sign}${currencySymbols[currency]}${Math.abs(amount).toFixed(round)}`
  }
}

export default money
