import { createSlice } from '@reduxjs/toolkit'

const initialState = []

// TODO: useless => remove later
export const nearMeDataSlice = createSlice({
  name: 'nearMeDataSlice',
  initialState,
  reducers: {
    updateNearMeData: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { updateNearMeData } = nearMeDataSlice.actions

export default nearMeDataSlice.reducer
