import React from 'react'
import util from './index'
import * as _ from 'lodash-es'
import { Translation } from 'react-i18next'
/**
 * @deprecated do not use it
 */
const price = {
  payNowTotalAmount(extendedPrice) {
    return this.actualDeposit(extendedPrice) + this.extra(extendedPrice)
  },

  waitlistPrice(extendedPrice) {
    return this.payNowTotalAmount(extendedPrice) // extras should be zero when HDSERV
  },

  payWhenBookedPrice(extendedPrice) {
    return extendedPrice.rentalPrice.whenBookedFee
  },

  actualDeposit(extendedPrice) {
    return extendedPrice.rentalPrice.discountedDeposit || extendedPrice.rentalPrice.fullDeposit
  },

  payNowTotalAmountBeforeDiscount(extendedPrice) {
    const deposit = extendedPrice.rentalPrice.fullDeposit
    return deposit + this.extra(extendedPrice)
  },

  extra(extendedPrice) {
    if (_.isEmpty(extendedPrice?.ancillaryProducts)) return 0
    return extendedPrice?.ancillaryProducts?.reduce(
      (acc, cur) => (extendedPrice.selectedAncillaries.includes(cur.id) ? acc + cur.price.price : acc),
      0
    )
  },

  discountAmount(extendedPrice) {
    return (
      extendedPrice.rentalPrice.fullDeposit -
      (extendedPrice.rentalPrice.discountedDeposit || extendedPrice.rentalPrice.fullDeposit)
    )
  },

  currency(extendedPrice) {
    return extendedPrice.rentalPrice.currency
  },

  isDiscounted(extendedPrice) {
    return extendedPrice.rentalPrice.discountedDeposit !== null
  },

  isFullyPrepaid(extendedPrice) {
    return extendedPrice.rentalPrice.remainder < 1e-5
  },

  priceState(extendedPrice) {
    return this.isFullyPrepaid(extendedPrice)
      ? this.isDiscounted(extendedPrice)
        ? 'FULL_PREPAYMENT_WITH_DISCOUNT'
        : 'FULL_PREPAYMENT'
      : this.isDiscounted(extendedPrice)
      ? 'DISCOUNTED'
      : 'NORMAL'
  },

  payNowPrice(extendedPrice) {
    this.isDiscounted(extendedPrice)
      ? this.payNowTotalAmountBeforeDiscount(extendedPrice)
      : this.payNowTotalAmount(extendedPrice)
  },

  payAtCounterPrice(extendedPrice) {
    return extendedPrice.rentalPrice.remainder
  },

  grandTotalNormalCars(extendedPrice) {
    return this.payNowTotalAmount(extendedPrice) + this.payAtCounterPrice(extendedPrice)
  },

  grandTotalHDSERVCars(extendedPrice) {
    return (
      this.payNowTotalAmount(extendedPrice) +
      this.payWhenBookedPrice(extendedPrice) +
      this.payAtCounterPrice(extendedPrice)
    )
  },

  /**
   * @deprecated - to be remove
   */
  getPriceInfoDetailsForPayNow(extendedPrice) {
    const priceInfoDetails = []
    let priceInfoItem = {
      header: <Translation>{t => t('xo2:prepayment')}</Translation>,
      description: (
        <Translation>
          {t =>
            util.formatMoney(this.actualDeposit(extendedPrice), this.currency(extendedPrice)) +
            ' ' +
            t('xo2:willBeChargedNowMessage')
          }
        </Translation>
      )
    }
    priceInfoDetails.push(priceInfoItem)

    const ancillaryItems = this.makePriceInfoItemsForAncillaries(extendedPrice)
    priceInfoDetails.push(...ancillaryItems)

    extendedPrice.extraRentalPrice && priceInfoDetails.push(...extendedPrice.extraRentalPrice)
    return {
      title: this.isFullyPrepaid(extendedPrice) ? (
        <Translation>{t => t('xo2:payNowMessage')}</Translation>
      ) : (
        <Translation>{t => t('xo2:payTotalMessage')}</Translation>
      ),
      subtitle: (
        <Translation>
          {t =>
            `${util.formatMoney(this.payNowTotalAmount(extendedPrice), this.currency(extendedPrice))} ${t(
              'xo2:willBeChargedMessage'
            )}`
          }
        </Translation>
      ),
      items: priceInfoDetails
    }
  },

  /**
   * @deprecated - to be remove
   */
  makePriceInfoItemsForAncillaries(extendedPrice) {
    if (!extendedPrice.ancillaryProducts) return []
    return extendedPrice.ancillaryProducts
      .filter(ap => extendedPrice.selectedAncillaries.includes(ap.id))
      .map(ap => {
        const priceString = util.formatMoney(ap.price.price, ap.price.currency)
        return {
          header: ap.title,
          description: (
            <Translation>{t => `${priceString}  ${t('xo2:willBeChargedForMessage')} ${ap.title}.`}</Translation>
          )
        }
      })
  },

  getPriceInfoDetailsForWaitlistFee(extendedPrice) {
    const priceString = util.formatMoney(this.waitlistPrice(extendedPrice), extendedPrice.rentalPrice.currency)

    const waitlistFeeDetails = [
      {
        header: 'Waitlist Fee',
        description: <Translation>{t => t('xo2:willContactMultipleCarMessage')}</Translation>
      },
      {
        header: '',
        description: <Translation>{t => t('xo2:willDeductThisAmountMessage')}</Translation>
      },
      {
        header: '',
        description: `In the unlikely event of all the cars are sold out, the waitlist fee of ${priceString} is not refundable.`
      }
    ]

    return {
      title: <Translation>{t => t('xo2:payTotalMessage')}</Translation>,
      subtitle: (
        <Translation>
          {t =>
            `${util.formatMoney(this.waitlistPrice(extendedPrice), this.currency(extendedPrice))} ${t(
              'xo2:willBeChargedMessage'
            )}`
          }
        </Translation>
      ),
      items: waitlistFeeDetails
    }
  },

  getPriceInfoDetailsForPayWhenBooked(extendedPrice) {
    const priceString = util.formatMoney(this.payWhenBookedPrice(extendedPrice), extendedPrice.rentalPrice.currency)

    const payWhenBookedDetails = [
      {
        header: 'Prepayment',
        description: <Translation>{t => `${priceString}  ${t('xo2:willBeChargedFromYourCard')}`}</Translation>
      },
      {
        header: '',
        description: <Translation>{t => t('xo2:waitListFeeMessage')}</Translation>
      }
    ]

    return {
      title: '',
      subtitle: '',
      items: payWhenBookedDetails
    }
  }
}
export default price
