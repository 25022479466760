import React from 'react'
import { Redirect } from 'react-router-dom'
import landingPages from './LandingPage'
import Loadable from '@base/loadable'

// Important: do not dynamic loading article and blog (SSR issue)
import Article from '@routes/Blog/Article'
import Blog from '@routes/Blog'

const ModificationOptions = Loadable(
  React.lazy(() => import('@components/Modification/components/ModificationOptions'))
)
const ModifyDriver = Loadable(React.lazy(() => import('@components/Modification/components/ModifyDriver')))
const ModifyDriverConfirm = Loadable(
  React.lazy(() => import('@components/Modification/components/ModifyDriverConfirm'))
)
const ModifyCarConfirm = Loadable(React.lazy(() => import('@components/Modification/components/ModifyCarConfirm')))
const ConfirmModification = Loadable(
  React.lazy(() => import('@components/Modification/components/ConfirmModification'))
)
const ModifySuccess = Loadable(React.lazy(() => import('@components/Modification/components/ModifySuccess')))
const ModifiedCarSearch = Loadable(React.lazy(() => import('@components/Modification/components/ModifiedCarSearch')))
const ModifyDateTime = Loadable(React.lazy(() => import('@components/Modification/components/ModifyDateTime')))
const ModifyLocation = Loadable(React.lazy(() => import('@components/Modification/components/ModifyLocation')))

const CancellationResult = Loadable(React.lazy(() => import('@components/Reservations/CancellationResult')))
const CancellationOptions = Loadable(React.lazy(() => import('@components/Reservations/CancellationOptions')))
const CancellationForm = Loadable(React.lazy(() => import('@components/Reservations/CancellationForm')))
const ReservedCarCard = Loadable(React.lazy(() => import('@components/Reservations/ReservedCarCard')))

const ReviewUs = Loadable(React.lazy(() => import('@routes/ReviewUs')))
const Feedback = Loadable(React.lazy(() => import('@routes/Feedback')))
const AvailableAreas = Loadable(React.lazy(() => import('@routes/AvailableAreas')))

const landingRoutes = landingPages.map(({ id, url, component }) => ({
  id,
  component,
  path: url,
  exact: true
}))

export default [
  {
    id: 'home',
    path: '/',
    exact: true
  },
  ...landingRoutes,
  //OLC
  {
    id: 'rental-cars',
    path: '/rental-cars/:pickupLocationId/:dropOffLocationId/:pickupDateStr/:dropOffDateStr', // query: age
    exact: true
  },
  {
    id: 'xo1',
    path: '/reservation',
    exact: true
  },
  {
    id: 'skyscanner',
    path: 'book',
    exact: true
  },
  {
    id: 'mockXO2',
    path: '/mock-payment',
    exact: true
  },

  {
    id: 'xo2',
    path: '/payment',
    exact: true
  },
  {
    id: 'hotel-xo2',
    path: '/hotel-payment',
    exact: true
  },
  {
    id: 'bundle-xo2',
    path: '/bundle-payment',
    exact: true
  },
  {
    id: 'success',
    path: '/success',
    exact: true
  },
  {
    id: 'my-reservations',
    path: '/my-reservations',
    exact: true
  },
  {
    id: 'reservation',
    path: '/my-reservations/reservation',
    exact: true,
    component: ReservedCarCard
  },
  {
    id: 'cancellation',
    path: '/my-reservations/cancellation',
    exact: true,
    component: CancellationForm
  },
  {
    id: 'cancellationOptions',
    path: '/my-reservations/cancellation/options',
    exact: true,
    component: CancellationOptions
  },
  {
    id: 'cancellationResult',
    path: '/my-reservations/cancellation/result',
    exact: true,
    component: CancellationResult
  },
  {
    id: 'blog',
    path: '/blog',
    exact: true,
    component: Blog
  },
  {
    id: 'blog-html',
    path: '/blog/index.html',
    exact: true,
    component: Blog
  },
  {
    id: 'blog-post',
    path: '/blog/:slug.html',
    exact: true,
    component: Article
  },
  {
    id: 'contact',
    path: '/contact',
    exact: true
  },
  {
    id: 'under25help',
    path: '/under25help',
    exact: true
  },

  {
    id: 'terms-conditions',
    path: '/terms-conditions',
    exact: true
  },

  {
    id: 'privacy-policy',
    path: '/privacy-policy',
    exact: true
  },

  {
    id: 'sitemap',
    path: '/sitemap',
    exact: true
  },
  // DEEP_LINKS
  {
    id: 'deepLink',
    path: '/jumpTo',
    exact: false,
    component: () => <Redirect to='/' />
  },
  {
    id: 'review-us',
    path: '/reviewUs',
    exact: true,
    component: ReviewUs
  },
  {
    id: 'feedback',
    path: '/feedback/:id/:reservationId', // query: score
    exact: true,
    component: Feedback
  },
  {
    id: 'available-areas',
    path: '/availableAreas',
    exact: true,
    component: AvailableAreas
  },
  {
    id: 'hotel-home',
    path: '/hotels',
    exact: true
  },
  {
    id: 'bundle-home',
    path: '/hotel-bundle',
    exact: true
  },
  {
    id: 'hotel-results',
    path: '/hotel-results/:latitude/:longitude/:pickupDateStr/:dropOffDateStr',
    exact: true
  },
  {
    id: 'reservation-page',
    path: '/hotel-reservation',
    exact: true
  },
  {
    id: 'bundle-page',
    path: '/bundle-reservation',
    exact: true
  },
  {
    id: 'bundle-list',
    path: '/hotel-bundle/list',
    exact: true
  },
  {
    id: 'redirect',
    path: '/redirect/:departdatetime/:returndatetime/:pickup/:dropoff/:driverAge/:carId',
    exact: true
  },
  {
    id: 'modification-options',
    path: '/my-reservations/modification-options',
    exact: true,
    component: ModificationOptions
  },
  {
    id: 'modify-driver',
    path: '/my-reservations/modify-driver',
    exact: true,
    component: ModifyDriver
  },
  {
    id: 'modify-driver-confirm',
    path: '/my-reservations/modify-driver-confirm',
    exact: true,
    component: ModifyDriverConfirm
  },
  {
    id: 'modifiy-car-confirm',
    path: '/my-reservations/modify-car-confirm',
    exact: true,
    component: ModifyCarConfirm
  },
  {
    id: 'modify-booking-confirm',
    path: '/my-reservations/modify-booking-confirm',
    exact: true,
    component: ConfirmModification
  },
  {
    id: 'modify-success',
    path: '/my-reservations/modify-success',
    exact: true,
    component: ModifySuccess
  },
  {
    id: 'modified-car-search',
    path: '/my-reservations/modified-car-search',
    exact: true,
    component: ModifiedCarSearch
  },
  {
    id: 'modify-date-time',
    path: '/my-reservations/modify-date-time',
    exact: true,
    component: ModifyDateTime
  },
  {
    id: 'modify-location',
    path: '/my-reservations/modify-location',
    exact: true,
    component: ModifyLocation
  },
  {
    id: 'modify-location',
    path: '/my-reservations/change-vehicle',
    exact: true,
    component: ModifyLocation
  },
  {
    id: 'fallback',
    path: '*'
  }
]
