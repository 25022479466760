import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const FeatureCardsContainer = styled.div`
  // homepage searchbar absolute element so, margin set high value
  // dynamic landing page searchbar static element so, margin set low value
  margin-top: 840px;
  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    margin-top: 680px;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    margin-top: 900px;
  }
`

export const HeaderTitle = styled.h1`
  font-family: Montserrat;
  margin: 0;
  font-size: 25px;
  font-weight: 700 !important;
  text-align: center;
  color: #fff;
  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    font-family: 'Montserrat';
    font-size: 52px;
    cursor: default;
  }
`
export const SearchBarContainer = styled.div<{ $us: boolean }>`
  width: 100%;
  height: calc(100vh + 20px);
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.$us ? `url(${'/images/home-image-us.webp'})` : `url(${'/images/home-image-nonus.webp'})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 140px;
  gap: 10px;
  position: absolute;
  top: -20px;
  z-index: 1;

  @media only screen and (min-width: ${themeGet('breakpoints.0')}px) {
    height: 850px;
  }
  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    height: 754px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 190px;
  }
  &::before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

export const AnchorButton = styled.div`
  width: 320px;
  height: 50px;
  border: 2px solid ${themeGet('colors.colorPrimary')};
  color: ${themeGet('colors.colorPrimary')};
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  padding: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -40px;
  margin-bottom: 40px;
`
