import { useQuery } from 'react-query'
import { fetchArticleBySlug } from './../sanity'

export function useArticlesBySlug(slug) {
  const { data, isLoading, error } = useQuery(['sanity-article', slug], () => fetchArticleBySlug(slug))

  return {
    data,
    isLoading,
    error
  }
}
