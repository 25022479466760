import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const promoCodeIsOpen = createSlice({
  name: 'promoCodeIsOpen',
  initialState,
  reducers: {
    setPromoCodeOpen: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setPromoCodeOpen } = promoCodeIsOpen.actions

export default promoCodeIsOpen.reducer
