import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { i18next, I18nextProvider, i18Resources } from './../i18'
import App from './App'
import { GlobalStyle } from './styles'

const container = document.getElementById('root')
const initialLanguage = window.initialLanguage || 'en'

hydrateRoot(
  container,
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <GlobalStyle />
        <App initialI18nStore={i18Resources} initialLanguage={initialLanguage} />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  {
    onRecoverableError: (error, errorInfo) => {
      if (!import.meta.env.DEV) return
      console.error({
        error,
        componentStack: errorInfo.componentStack
      })
    }
  }
)
