import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ArticleContent = styled('article')`
  &:focus {
    outline: none;
  }
  .main-container {
    padding: 25px 0 15px;
    & > * {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      @media screen and (min-width: ${themeGet('breakpoints.1')}px) {
        width: 750px;
        max-width: ${themeGet('breakpoints.1')}px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
    .image-full,
    .image-single,
    .image-double {
      padding-left: 4px;
      padding-right: 4px;
      @media screen and (min-width: ${themeGet('breakpoints.1')}px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .image-single.image-single-narrow {
      text-align: center;
      img {
        max-width: 300px;
      }
    }
    .image-double {
      text-align: center;
      @media screen and (min-width: ${themeGet('breakpoints.0')}px) {
        img {
          width: 45%;
        }
      }
    }
    .image-half {
      text-align: center;
      img {
        width: 50%;
      }
      @media screen and (min-width: ${themeGet('breakpoints.0')}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  // full width on mobile, float on desktop
  .image-paragraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .image {
      margin: 0 -15px;
      img {
        max-width: 100%;
      }
    }

    @media (min-width: ${themeGet('breakpoints.0')}px) {
      flex-wrap: nowrap;
      .text {
        width: 55%;
      }
      .image {
        width: 42%;
        margin: 0;
      }
    }
  }

  // this is copied from image image-paragraph
  .surprise-supplier-paragraph {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .image {
      margin: auto;
      img {
        width: 160px;
      }
    }

    @media (min-width: ${themeGet('breakpoints.0')}px) {
      flex-wrap: nowrap;
      .text {
        width: 80%;
      }
      .image {
        width: 160px;
        margin: 0;
      }
    }
  }

  .image-caption {
    text-align: center;
    margin-top: 0;
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${themeGet('colors.blueGrey.8')};
    font-weight: lighter;
  }
  h1 {
    font-size: 26px;
    text-align: center;
  }
  h2 {
    margin-top: 30px;
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    text-align: justify;
    color: ${themeGet('colors.blueGrey.7')};
  }
  a {
    display: inline-block;
    color: ${themeGet('colors.green.9')};
    transition: color 100ms ease-in-out;
    &:hover {
      color: ${themeGet('colors.green.7')};
    }
  }
  .blog-list {
    padding-left: 40px;
    color: ${themeGet('colors.blueGrey.7')};
    li.blog-list-item {
      padding: 8px 0;
    }
  }

  .blog-list-2 {
    padding-left: 40px;
    color: ${themeGet('colors.blueGrey.7')};
    li.blog-list-item {
      padding: 0;
    }
  }

  .check-list {
    padding-left: 40px;
    list-style-type: none;
    color: ${themeGet('colors.blueGrey.7')};
    column-count: 2;

    li {
      padding: 8px 8px;
    }

    li::before {
      content: '\\2714';
      position: relative;
      padding: inherit;
    }
  }

  .blog-table-container {
    padding-left: 0;
    padding-right: 0;
    overflow: auto;
    &:not(.fixed) {
      width: 100%;
    }
    &.fixed {
      .blog-table {
        width: 100%;
      }
    }
    .blog-table {
      margin: auto;
      tr {
        background-color: ${themeGet('colors.newColorPrimary')};
        td,
        th {
          text-align: left;
          padding: 8px;
        }
      }
    }
  }
`
