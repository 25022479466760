const loadBodyScript = (options = {}) => {
  const { key, src, isAsync = false, callback } = options
  if (!key || !src) return
  const existingScript = document.getElementById(key)
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = src
    script.id = key
    script.async = isAsync
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}
export default loadBodyScript
