import { addDays, differenceInDays, format, isSameDay, parse } from 'date-fns'
import { fr, it, de, es, ptBR } from 'date-fns/locale'
import util from './index'
import SessionManager from '../services/SessionManager'

export const trLocale = {
  code: 'tr',
  // Define month and weekday names manually
  localize: {
    month: monthIndex =>
      ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'][monthIndex],

    day: dayIndex => ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'][dayIndex],
    dayPeriod: () => ''
  },

  // Add formatLong property to handle long formats
  formatLong: {
    date: () => 'dd.MM.yyyy', // Long format for date
    time: () => 'HH:mm', // Long format for time
    dateTime: () => 'dd.MM.yyyy HH:mm' // Long format for date-time
  },

  options: {
    weekStartsOn: 1, // Monday
    firstWeekContainsDate: 1
  }
}

const locales = { fr, it, de, es, tr: trLocale, pt: ptBR }

function doubleChar(number) {
  return number < 10 ? `0${number}` : String(number)
}

const dateUtil = {
  getFormat(language) {
    const formats = {
      US: ['MM/dd EEE', 'MMM d EEE', 'MM/dd EEE', 'MM/dd/yyyy'],
      EU: ['dd/MM EEE', 'd MMM EEE', 'dd/MM EEE', 'dd/MM/yyyy']
    }
    switch (language) {
      case 'fr':
        return formats.EU
      case 'it':
        return formats.EU
      case 'de':
        return formats.EU
      case 'es':
        return formats.EU
      case 'tr':
        return formats.EU
      case 'pt':
        return formats.EU
      default:
        return formats.US
    }
  },
  getTimeFormatStr() {
    const country = SessionManager.getUserCountry()
    if (!country || country === 'US') return 'h:mm a'
    return 'HH:mm'
  },

  /**
   * sets time fields of Date object to zero
   */
  normalizeDate(date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  },

  /**
   * takes Date object and returns YYYYMMDDhhmm string
   */
  formatDateForAPI(date) {
    return format(date, 'yyyyMMddHHmm')
  },

  /**
   * takes Date and returns "12/31/2019 09:30 AM" string
   */
  formatDateTimeFromDate(date, language) {
    return format(date, `${this.getFormat(language)[3]} ${this.getTimeFormatStr()}`)
  },

  /**
   * takes Date and returns "09.01 Fri 12:00 AM" string
   */
  formatDateAndDayTimeFromDate(date, language = 'en') {
    return format(date, `${this.getFormat(language)[2]} ${this.getTimeFormatStr()}`, { locale: locales[language] })
  },

  /**
   * takes {hour:16, min: 0} and returns "16:00"
   */
  formatTimeObj({ hour, min }) {
    if (!util.isNum(hour) || !util.isNum(min)) return false
    const hourText = doubleChar(hour)
    const minText = doubleChar(min)
    return `${hourText}:${minText}`
  },

  /**
   * takes {hour:16, min: 0} and returns "04:00 PM"
   */
  formatTimeObjUS({ hour, min }) {
    if (!util.isNum(hour) || !util.isNum(min)) return false
    return format(new Date(null, null, null, hour, min), this.getTimeFormatStr())
  },

  /**
   * takes 2 Date and returns "09.01 Fri 12:00 AM - 09.02 Sat 01:30 PM"|"09.01 Fri 12:00 AM - 01:30 PM"
   * @param pickupDate {Date}
   * @param dropOffDate {Date}
   * @returns {string|null}
   */
  formatDateTimeRange(pickupDate, dropOffDate, locale = 'en') {
    if (!pickupDate || !dropOffDate) return null
    const formats = this.getFormat(locale)
    let result = format(pickupDate, `${formats[0]} ${this.getTimeFormatStr()}`, { locale: locales[locale] })
    if (isSameDay(pickupDate, dropOffDate)) {
      result += ' - ' + format(dropOffDate, this.getTimeFormatStr())
    } else {
      result += ' - ' + format(dropOffDate, `${formats[0]} ${this.getTimeFormatStr()}`, { locale: locales[locale] })
    }
    return result
  },

  /**
   * takes 2 Date and returns "09.01 Fri - 09.02 Sat"|"09.01 Fri -"
   * @param pickupDate {Date}
   * @param dropOffDate {Date}
   * @returns {string|null}
   */
  formatDateRangeWithoutTime(pickupDate, dropOffDate, locale = 'en') {
    if (!pickupDate || !dropOffDate) return null
    const formats = this.getFormat(locale)
    let result = format(pickupDate, formats[0], { locale: locales[locale] })
    if (isSameDay(pickupDate, dropOffDate)) {
      result += ' - ' + format(dropOffDate, formats[0])
    } else {
      result += ' - ' + format(dropOffDate, formats[0], { locale: locales[locale] })
    }
    return result
  },

  /*
   * takes Date and returns "Aug 12 Fri" if same date, else "Aug 12 Fri - Aug 17 Wed"
   */
  formatDateTimeRangeWithoutTime(pickupDate, dropOffDate, language = 'en') {
    if (!pickupDate || !dropOffDate) return null
    let result = format(pickupDate, 'MMM  d  EEE', { locale: locales[language] }) + ''
    if (!isSameDay(pickupDate, dropOffDate)) {
      result += ' - ' + format(dropOffDate, 'MMM  d  EEE', { locale: locales[language] })
    }
    return result
  },

  /**
   * takes Date and returns "09.01 Fri"
   */
  formatDateShort(date) {
    const today = new Date()
    if (isSameDay(date, today)) return 'Today'
    if (isSameDay(date, addDays(today, 1))) return 'Tomorrow'

    return format(date, 'MM/dd EEE')
  },

  /**
   * takes Date and returns "Tue Dec 11 2018"
   */
  formatBlogDate(date) {
    return format(date, 'EEE LLL dd yyyy')
  },

  /**
   * takes Date object and returns {hour, min}
   */
  getTimeObjFromDate(date) {
    if (!date) {
      return { hour: null, min: null }
    }
    return {
      hour: date.getHours(),
      min: date.getMinutes()
    }
  },

  /**
   * takes YYYYMMDDhhmm string and returns Date obj
   */
  getDateFromRedux(dateStr) {
    const strToParse = dateStr.substr(0, 20)
    return parse(strToParse, 'EEE LLL dd yyyy HH:mm', new Date())
  },

  /**
   * takes YYYYMMDDhhmm string and returns Date obj
   */
  getDateFromAPI(dateStr) {
    return parse(dateStr, 'yyyyMMddHHmm', new Date())
  },

  /**
   * takes YYYYMMDDhhmm string and returns {hour, min} obj
   */
  getTimeObjFromAPI(dateStr) {
    const date = this.getDateFromAPI(dateStr)
    return { hour: date.getHours(), min: date.getMinutes() }
  },

  /**
   * takes YYYYMMDDhhmm string and return "Thu Jun 02 2022, 12:00 PM"
   */
  getUTCTimeMinusTZ(dateStr) {
    const obj = this.getDateFromAPI(dateStr)
    const dateTime = format(obj, 'EEE LLL dd yyyy ' + this.getTimeFormatStr())
    return `${dateTime}`
  },

  /**
   * returns a date range from tomorrow to 2 days after
   * @returns {{from: Date, to Date}}
   */
  getDefaultDateRange() {
    const tomorrow = addDays(new Date().setHours(12, 0, 0, 0), 1)
    const end = addDays(tomorrow, 2)
    return {
      pickupDate: tomorrow.getTime(),
      dropOffDate: end.getTime(),
      startDate: tomorrow.getTime(),
      endDate: end.getTime()
    }
  },

  getNewDefaultDateRange() {
    const tomorrow = this.normalizeDate(addDays(new Date(), 1))
    const end = addDays(tomorrow, 2)
    return {
      startDate: tomorrow,
      endDate: end
    }
  },

  dayRange(date1, date2) {
    return differenceInDays(date1, date2)
  },

  //parses yyyyMMddHHmm string from car model and formats to MM/dd/yyyy HH:mm
  formatDate(date, language) {
    return format(parse(date, 'yyyyMMddHHmm', new Date()), `${this.getFormat(language)[3]} HH:mm`)
  },

  formatDateWithoutYearWithDay(date, language = 'en') {
    return format(
      parse(date, 'yyyyMMddHHmm', new Date()),
      `${this.getFormat(language)[2]} ${this.getTimeFormatStr()}`,
      {
        locale: locales[language]
      }
    )
  },

  //parses yyyyMMddHHmm string from car model and formats to MM/dd/yyyy
  formatDateWithoutTime(date) {
    return format(parse(date, 'yyyyMMddHHmm', new Date()), 'MM/dd/yy')
  },

  formatTime(date) {
    return format(parse(date, 'yyyyMMddHHmm', new Date()), 'HH:mm')
  },

  howManyDays(pickup_date, dropoff_date) {
    const scale_factor = 86400000 // 1000 * 60 * 60 * 24 (one day in ms)

    //Date library takes 0-11, 0 is January
    const date1 = new Date(
      pickup_date.slice(0, 4),
      parseInt(pickup_date.slice(4, 6), 10) - 1,
      pickup_date.slice(6, 8),
      pickup_date.slice(8, 10),
      pickup_date.slice(10, 12)
    )

    const date2 = new Date(
      dropoff_date.slice(0, 4),
      parseInt(dropoff_date.slice(4, 6), 10) - 1,
      dropoff_date.slice(6, 8),
      dropoff_date.slice(8, 10),
      dropoff_date.slice(10, 12)
    )

    const dateDiff = Math.abs(date2 - date1)
    const result = Math.ceil(dateDiff / scale_factor) // difference in days, always round above

    // it does calculate 0 day if user picks:
    // pick up date -> last date of a month
    // drop off date -> first date of a month
    // return 1 if math ceil function returns 0
    return result > 0 ? result : 1
  }
}

export default dateUtil
