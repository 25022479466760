import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  available: null,
  threeDsInfo: null,
  discountPrice: null
}

export const threeDsSlice = createSlice({
  name: 'threeDsSlice',
  initialState,
  reducers: {
    setThreeDs: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setThreeDs } = threeDsSlice.actions

export default threeDsSlice.reducer
