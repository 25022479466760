const funnelEvent = ({ action, ...params }) => {
  window.gtag('event', action, {
    ...params,
    event_category: 'funnel'
  })
}
const interactionEvent = ({ action, ...params }) => {
  window.gtag('event', action, {
    ...params,
    event_category: 'interaction'
  })
}

export const searchNearMeLocations = () =>
  funnelEvent({
    action: 'Search near me locations'
  })
export const selectPickupLocation = (locationName) =>
  funnelEvent({
    action: 'select pickup location',
    event_label: locationName
  })
export const selectDropOffLocation = (locationName) =>
  funnelEvent({
    action: 'search drop off location',
    event_label: locationName
  })
export const searchForCars = () =>
  funnelEvent({
    action: 'search for cars',
    event_label: 'user searched with details'
  })
export const getNoCars = () =>
  funnelEvent({
    action: 'get no cars',
    event_label: 'user found no cars',
    non_interaction: true
  })
export const selectCar = (index) =>
  funnelEvent({
    action: 'select car',
    value: index
  })
export const makeInsurance = () =>
  funnelEvent({
    action: 'select insurance',
    event_label: 'user started reservation with insurance'
  })
export const startReservation = () =>
  funnelEvent({
    action: 'start reservation',
    event_label: 'user started a reservation'
  })
export const startPayment = (totalAmount) =>
  funnelEvent({
    action: 'try payment',
    event_label: 'user started a payment process',
    value: totalAmount
  })
export const completePayment = (reservationId, totalAmount) => {
  funnelEvent({
    action: 'complete payment',
    event_label: 'user completed a payment process',
    value: totalAmount
  })
  // adwords conversion
  window.gtag('event', 'conversion', {
    send_to: 'AW-866277486/psv2CPuli5oBEO6wiZ0D',
    transaction_id: reservationId
  })
}

export const clickNavLogo = () =>
  interactionEvent({
    action: 'click carla logo',
    event_label: 'user clicked carla logo on navigation bar'
  })
export const clickDownloadApp = () =>
  interactionEvent({
    action: 'click download app',
    event_label: 'user clicked download app button on navigation bar'
  })
export const clickAppStoreBadge = () =>
  interactionEvent({
    action: 'click app store badge',
    event_label: 'user clicked footer app store badge'
  })
export const clickGooglePlayBadge = () =>
  interactionEvent({
    action: 'click google play badge',
    event_label: 'user clicked footer google play badge'
  })
export const openSidebar = () =>
  interactionEvent({
    action: 'open sidebar',
    event_label: 'user opened mobile navigation bar'
  })
export const goHomeAfterReservation = () =>
  interactionEvent({
    action: 'go home after reservation complete',
    event_label: 'confirmation to home'
  })
export const openTermsConditions = () =>
  interactionEvent({
    action: 'open terms&conditions',
    event_label: 'user opened terms&conditions modal'
  })
export const openRentalCoverInfo = () =>
  interactionEvent({
    action: 'open rental cover info',
    event_label: 'user opened rental cover info modal'
  })
export const clickTopBlogSearchButton = () =>
  interactionEvent({
    action: 'go to search from blog',
    event_label: 'user clicked navigation search icon from blog'
  })
export const clickBottomBlogSearchButton = () =>
  interactionEvent({
    action: 'go to search from blog',
    event_label: 'user clicked bottom search button from blog'
  })
export const clickSearchAll = () =>
  interactionEvent({
    action: 'go to car listing via search all',
    event_label: 'user clicked search all button on map'
  })
export const clickAnother = () =>
  interactionEvent({
    action: 'go to car listing via specific map location',
    event_label: 'user clicked a specific location on map'
  })
export const zoomOnMap = () =>
  interactionEvent({
    action: 'zoom in/out on map',
    event_label: 'user zoomed in/out on map'
  })
export const dragOnMap = () =>
  interactionEvent({
    action: 'drag on map',
    event_label: 'user dragged on map'
  })
export const eligibleForApplePay = () =>
  interactionEvent({
    action: 'eligible for apple pay',
    event_label: 'user is eligible for apple pay'
  })
export const completeReservationWithApplePay = () =>
  interactionEvent({
    action: 'complete reservation with apple pay',
    event_label: 'user completed reservation with apple pay'
  })
export const applePayFailed = () =>
  interactionEvent({
    action: 'apple pay failed',
    event_label: 'apple pay failed'
  })
export const tappedFilter = () =>
  interactionEvent({
    action: 'tapped filter',
    event_label: 'user tapped filter'
  })
export const selectedPickupDateOnMobile = () =>
  interactionEvent({
    action: 'selected pickup date on mobile',
    event_label: 'user selected pickup date on mobile'
  })
export const selectedDropOffDateOnMobile = () =>
  interactionEvent({
    action: 'selected dropoff date on mobile',
    event_label: 'user selected dropoff date on mobile'
  })
export const selectedPickupTimeOnMobile = () =>
  interactionEvent({
    action: 'selected pickup time on mobile',
    event_label: 'user selected pickup time on mobile'
  })
export const selectedDropOffTimeOnMobile = () =>
  interactionEvent({
    action: 'selected dropoff time on mobile',
    event_label: 'user selected dropoff time on mobile'
  })
export const completeReservationFailed = () =>
  interactionEvent({
    action: 'complete reservation failed',
    event_label: 'an error occurred while completing reservation'
  })
export const noLocationFound = () =>
  interactionEvent({
    action: 'no location found',
    event_label: 'user received no location found message on location search'
  })
export const googleLoginSuccess = () =>
  interactionEvent({
    action: 'google sign in successfull',
    event_label: 'user signed in with google'
  })
export const googleLoginFailure = () =>
  interactionEvent({
    action: 'google sign in failed',
    event_label: 'user failed to sign in with google'
  })
export const facebookLoginSuccess = () =>
  interactionEvent({
    action: 'facebook sign in successfull',
    event_label: 'user signed in with facebook'
  })
export const facebookLoginFailure = () =>
  interactionEvent({
    action: 'facebook sign in failed',
    event_label: 'user failed to sign in with facebook'
  })
export const selectTripProtection = () =>
  interactionEvent({
    action: 'select trip protection',
    event_label: 'user selected trip protection checkbox'
  })
export const unselectTripProtection = () =>
  interactionEvent({
    action: 'unselect trip protection',
    event_label: 'user unselected trip protection checkbox'
  })
export const dateSelectionFinishedOnDesktop = () =>
  interactionEvent({
    action: 'date selection finished on desktop',
    event_label: 'user closed the calender by clicking DONE button on desktop'
  })
export const selectCancelReasonCreditCard = () =>
  interactionEvent({
    action: 'cancellation_creditcard_web',
    event_label: 'user selected no credit card reason on cancellation reason selection form'
  })
export const selectCancelReasonConfirmationNumber = () =>
  interactionEvent({
    action: 'cancellation_confirmation_number_web',
    event_label: 'user selected confirmation number reason on cancellation reason selection form'
  })
export const selectCancelReasonMissingDocs = () =>
  interactionEvent({
    action: 'cancellation_missingdoc_web',
    event_label: 'user selected missing docs reason on cancellation reason selection form'
  })
export const selectCancelReasonDeposit = () =>
  interactionEvent({
    action: 'cancellation_deposit_web',
    event_label: 'user selected high deposit reason on cancellation reason selection form'
  })
export const selectCancelReasonOther = () =>
  interactionEvent({
    action: 'cancellation_other_web',
    event_label: 'user selected other reason on cancellation reason selection form'
  })
export const submitCancellationTerms = () =>
  interactionEvent({
    action: 'cancel_clicked_on_reasons_web',
    event_label: 'user submitted reasons form to cancellation terms enpoint'
  })
export const submitCompleteCancellation = () =>
  interactionEvent({
    action: 'cancel_clicked_on_final_page_web',
    event_label: 'user completed cancellation'
  })

///////// I couldn't implement the events below for different reasons. //////////
export const startedDateSelectionOnDesktop = () =>
  interactionEvent({
    action: 'started date selection on desktop',
    event_label: 'user started date selection on desktop'
  })
export const wentBackToSearchFromMap = () =>
  interactionEvent({
    action: 'went back to search from map',
    event_label: 'user went back to search from map'
  })
export const hotelHomePage = () => {
  funnelEvent({
    action: 'web_hotel_home_page',
    event_label: 'web_hotel_home_page'
  })
}
export const hotelSearch = (params) => {
  funnelEvent({
    action: 'web_hotel_search',
    event_label: 'web_hotel_search',
    ...params
  })
}
export const hotelListing = () => {
  funnelEvent({
    action: 'web_hotel_listing',
    event_label: 'web_hotel_listing'
  })
}

export const hotelDetailEvent = () => {
  funnelEvent({
    action: 'web_hotel_view_detail',
    event_label: 'web_hotel_view_detail'
  })
}

export const hotelXO1 = () => {
  funnelEvent({
    action: 'web_hotel_xo1',
    event_label: 'web_hotel_xo1'
  })
}
export const hotelXO2 = () => {
  funnelEvent({
    action: 'web_hotel_xo2',
    event_label: 'web_hotel_xo2'
  })
}
export const hotelBooked = () => {
  funnelEvent({
    action: 'web_hotel_booked',
    event_label: 'web_hotel_booked'
  })
}
export const bundleHomePageEvent = () => {
  funnelEvent({
    action: 'web_hotel_bundle_home',
    event_label: 'web_hotel_bundle_home'
  })
}

export const bundleSearchEvent = () => {
  funnelEvent({
    action: 'web_hotel_bundle_search',
    event_label: 'web_hotel_bundle_search'
  })
}

export const bundleListingEvent = () => {
  funnelEvent({
    action: 'web_hotel_bundle_list',
    event_label: 'web_hotel_bundle_list'
  })
}

export const bundleViewDetailEvent = () => {
  funnelEvent({
    action: 'web_hotel_bundle_view_detail',
    event_label: 'web_hotel_bundle_view_detail'
  })
}

export const bundleXO1Event = () => {
  funnelEvent({
    action: 'web_hotel_bundle_xo1',
    event_label: 'web_hotel_bundle_xo1'
  })
}

export const bundleXO2Event = () => {
  funnelEvent({
    action: 'web_hotel_bundle_xo2',
    event_label: 'web_hotel_bundle_xo2'
  })
}

export const bundleSuccessEvent = () => {
  funnelEvent({
    action: 'web_hotel_bundle_success',
    event_label: 'web_hotel_bundle_success'
  })
}

// A/B testing
export const viewExperiments = (experiment, result) => {
  funnelEvent({
    action: 'web_ab_view_experiment',
    event_label: 'web_ab_view_experiment',
    experimentId: experiment.key,
    variationId: result.variationId
  })
}

export const processToXO2 = (attributes) => {
  funnelEvent({
    action: 'web_ab_process_to_xo2',
    event_label: 'web_ab_process_to_xo2',
    detail: {
      attributes
    }
  })
}

// Contact form update
export const contactFormUserEvent = (eventName) => {
  interactionEvent({
    action: eventName,
    event_label: eventName
  })
}

export const selectGetAround = () => {
  interactionEvent({
    action: 'web_select_getaround',
    eventLabel: 'web_select_getaround'
  })
}

export const affirmClicked = () => {
  interactionEvent({
    action: 'xo2_affirm_clicked_web',
    eventLabel: 'xo2_affirm_clicked_web'
  })
}

export const affirmPurchased = () => {
  funnelEvent({
    action: 'xo2_affirm_purchased_web',
    eventLabel: 'xo2_affirm_purchased_web'
  })
}

export const affirmCancelled = () => {
  interactionEvent({
    action: 'xo2_affirm_cancelled_web',
    eventLabel: 'xo2_affirm_cancelled_web'
  })
}

// Google Analytics
export default {
  searchNearMeLocations,
  selectPickupLocation,
  selectDropOffLocation,
  searchForCars,
  getNoCars,
  selectCar,
  makeInsurance,
  startReservation,
  startPayment,
  completePayment,
  clickNavLogo,
  clickDownloadApp,
  clickAppStoreBadge,
  clickGooglePlayBadge,
  openSidebar,
  goHomeAfterReservation,
  openTermsConditions,
  openRentalCoverInfo,
  clickTopBlogSearchButton,
  clickBottomBlogSearchButton,
  clickSearchAll,
  clickAnother,
  zoomOnMap,
  dragOnMap,
  eligibleForApplePay,
  completeReservationWithApplePay,
  applePayFailed,
  tappedFilter,
  selectedPickupDateOnMobile,
  selectedDropOffDateOnMobile,
  selectedPickupTimeOnMobile,
  selectedDropOffTimeOnMobile,
  completeReservationFailed,
  noLocationFound,
  googleLoginSuccess,
  googleLoginFailure,
  facebookLoginSuccess,
  facebookLoginFailure,
  selectTripProtection,
  unselectTripProtection,
  dateSelectionFinishedOnDesktop,
  selectCancelReasonCreditCard,
  selectCancelReasonConfirmationNumber,
  selectCancelReasonMissingDocs,
  selectCancelReasonDeposit,
  selectCancelReasonOther,
  submitCancellationTerms,
  submitCompleteCancellation,
  startedDateSelectionOnDesktop,
  wentBackToSearchFromMap,
  hotelHomePage,
  hotelSearch,
  hotelListing,
  hotelDetailEvent,
  hotelXO1,
  hotelXO2,
  hotelBooked,
  bundleHomePageEvent,
  bundleSearchEvent,
  bundleListingEvent,
  bundleViewDetailEvent,
  bundleXO1Event,
  bundleXO2Event,
  bundleSuccessEvent,
  viewExperiments,
  processToXO2,
  contactFormUserEvent,
  selectGetAround,
  affirmClicked,
  affirmPurchased,
  affirmCancelled
}
