import { Fragment, lazy } from 'react'
import { Helmet } from 'react-helmet'
// import SearchTabs from '@components/SearchTabs'
import { HeaderTitle, SearchBarContainer } from './Home.styled'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useCheckCountry from '@hooks/useCheckCountry'
import FeatureCards from '@components/HomePage/FeatureCards'
import Loadable from '@base/loadable'
import { useHiddenByLang } from '@base/hooks/useHiddenByLang'

const BlogSlide = Loadable(lazy(() => import('@components/HomePage/BlogSlide')))
const FAQsHomePage = Loadable(lazy(() => import('@components/HomePage/FAQsHomePage')))
const Press = Loadable(lazy(() => import('@components/HomePage/Press')))
const DownloadAppBanner = Loadable(lazy(() => import('@components/DownloadAppBanner')))
const Search = Loadable(lazy(() => import('@components/SearchBoxV3/CarSearch')))

const Home = ({ history }) => {
  const { t } = useTranslation()
  const isAllowedCountry = useCheckCountry()
  const useCountry = useSelector((state) => state.user.country) || 'US' // Default for US
  const hiddenByLang = useHiddenByLang()

  return (
    <Fragment>
      <Helmet>
        <title>{t('homePage:title')}</title>
        <link rel='canonical' href='https://rentcarla.com/' />
      </Helmet>
      <SearchBarContainer $us={useCountry === 'US'}>
        <HeaderTitle>{t('homePage:header')}</HeaderTitle>
        <Search history={history} />
      </SearchBarContainer>
      <FeatureCards
        memorialDay={undefined}
        italy={undefined}
        isPageDynamic={undefined}
        data={undefined}
        spring={undefined}
      />
      {isAllowedCountry && <DownloadAppBanner />}
      {!hiddenByLang && <BlogSlide />}
      <FAQsHomePage />
      <Press />
    </Fragment>
  )
}

export default Home
