////////  THIS IS DATA FILE THAT HAS BEEN USED BY DYNAMIC LANDING PAGES      ////////
///////  TO CREATE A DYNAMIC PAGE, ALL THE DATA OBJECT HAS TO BE FULFILLED  ////////
//////  All required data for dynamic landing page components              ////////
/////  All maps have to be updated when a new landing page is implemented ////////

// titles and meta descriptions to improve
// seo and localization
// DynamicPages.js
export const seoDataMap = {
  '/orlando-car-rental': {
    title: 'Rent a Car in Orlando from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Orlando downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you. Visit Carla now.'
  },
  '/los-angeles-car-rental': {
    title: 'Rent a Car in Los Angeles | Carla Car Rental',
    description:
      'Best car rental rates in Los Angeles downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/fort-lauderdale-car-rental': {
    title: 'Rent a Car in Fort Lauderdale from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Fort Lauderdale downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/tampa-car-rental': {
    title: 'Rent a Car in Tampa from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Tampa downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/miami-car-rental': {
    title: 'Rent a Car in Miami from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Miami downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/dallas-car-rental': {
    title: 'Rent a Car in Dallas from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Dallas downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/phoenix-car-rental': {
    title: 'Rent a Car in Phoenix from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Phoenix downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/tucson-car-rental': {
    title: 'Rent a Car in Tucson from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Tucson downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/las-vegas-car-rental': {
    title: 'Rent a Car in Las Vegas from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Las Vegas downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/new-york-city-car-rental': {
    title: 'Rent a Car in NYC from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in NYC downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/brooklyn-car-rental': {
    title: 'Rent a Car in Brooklyn from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Brooklyn downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/atlanta-car-rental': {
    title: 'Rent a Car in Atlanta from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Atlanta downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/san-juan-car-rental': {
    title: 'Rent a Car in San Juan from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in San Juan downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/chicago-car-rental': {
    title: 'Rent a Car in Chicago from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Chicago downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/san-diego-car-rental': {
    title: 'Rent a Car in San Diego from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in San Diego downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/san-francisco-car-rental': {
    title: 'Rent a Car in San Francisco from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in San Francisco downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/montauk-car-rental': {
    title: 'Rent a Car in Montauk from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Montauk downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/bronx-car-rental': {
    title: 'Rent a Car in Bronx from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Bronx downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/buffalo-car-rental': {
    title: 'Rent a Car in Buffalo from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Buffalo downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/niagara-falls-car-rental': {
    title: 'Rent a Car in Niagara Falls from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Niagara Falls downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/queens-car-rental': {
    title: 'Rent a Car in Queens from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Queens downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/southampton-car-rental': {
    title: 'Rent a Car in Southampton from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Southampton downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/jacksonville-car-rental': {
    title: 'Rent a Car in Jacksonville from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Jacksonville downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/kissimmee-car-rental': {
    title: 'Rent a Car in Kissimmee from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Kissimmee downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/fort-myers-car-rental': {
    title: 'Rent a Car in Fort Myers from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Fort Myers downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/naples-car-rental': {
    title: 'Rent a Car in Naples from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Naples downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/malibu-car-rental': {
    title: 'Rent a Car in Malibu from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Malibu downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/miami-beach-car-rental': {
    title: 'Rent a Car in Miami Beach from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Miami Beach downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/st-petersburg-car-rental': {
    title: 'Rent a Car in St. Petersburg from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in St. Petersburg downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/panama-city-car-rental': {
    title: 'Rent a Car in Panama City from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Panama City downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/tallahassee-car-rental': {
    title: 'Rent a Car in Tallahassee from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Tallahassee downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/sacramento-car-rental': {
    title: 'Rent a Car in Sacramento from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Sacramento downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/utica-car-rental': {
    title: 'Rent a Car in Utica from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Utica downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/portland-car-rental': {
    title: 'Rent a Car in Portland from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Portland downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/tahoe-city-car-rental': {
    title: 'Rent a Car in Tahoe City from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Tahoe City downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/palm-springs-car-rental': {
    title: 'Rent a Car in Palm Springs from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Palm Springs downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/spring-car-rental': {
    title: 'Rent a Car for Spring from AVIS, Hertz, Europcar | Carla',
    description:
      'Best car hire rates in most popular Spring holiday locations starting from £10/day. Compare the car hire prices with Carla and find the best one for you.'
  },
  '/italy-car-rental': {
    title: 'Rent a Car in Italy from AVIS, Hertz, Enterprise | Carla',
    description:
      'Best car rental rates in Italy downtown & Airport starting from $10/day. Compare the car rental prices with Carla and find the best one for you.'
  },
  '/memorial-day-car-rental': {
    title: 'Rent a Car for Memorial Day from AVIS, Hertz, Europcar | Carla',
    description:
      'Best car hire rates in most popular Memorial Day locations starting from £10/day. Compare the car hire prices with Carla and find the best one for you.'
  }
}

// id map for sending request to get data
// must: city id, not airport
// DynamicPages.js
export const idDataMap = {
  '/orlando-car-rental': { id: 11570 },
  '/los-angeles-car-rental': { id: 10805 },
  '/fort-lauderdale-car-rental': { id: 11943 },
  '/tampa-car-rental': { id: 11012 },
  '/miami-car-rental': { id: 13036 },
  '/dallas-car-rental': { id: 9689 },
  '/phoenix-car-rental': { id: 11563 },
  '/tucson-car-rental': { id: 10073 },
  '/las-vegas-car-rental': { id: 12655 },
  '/new-york-city-car-rental': { id: 19128 },
  '/brooklyn-car-rental': { id: 19008 },
  '/atlanta-car-rental': { id: 10020 },
  '/san-juan-car-rental': { id: 16480 },
  '/chicago-car-rental': { id: 11487 },
  '/san-diego-car-rental': { id: 14755 },
  '/san-francisco-car-rental': { id: 11705 },
  '/montauk-car-rental': { id: 14645 },
  '/bronx-car-rental': { id: 19029 },
  '/buffalo-car-rental': { id: 10626 },
  '/niagara-falls-car-rental': { id: 12745 },
  '/queens-car-rental': { id: 19128 },
  '/southampton-car-rental': { id: 12677 },
  '/jacksonville-car-rental': { id: 11122 },
  '/kissimmee-car-rental': { id: 12934 },
  '/fort-myers-car-rental': { id: 11965 },
  '/naples-car-rental': { id: 9931 },
  '/malibu-car-rental': { id: 13167 },
  '/miami-beach-car-rental': { id: 19004 },
  '/st-petersburg-car-rental': { id: 15577 },
  '/panama-city-car-rental': { id: 11616 },
  '/tallahassee-car-rental': { id: 17135 },
  '/sacramento-car-rental': { id: 14233 },
  '/utica-car-rental': { id: 17393 },
  '/portland-car-rental': { id: 15480 },
  '/tahoe-city-car-rental': { id: 17107 },
  '/palm-springs-car-rental': { id: 15768 },
  '/spring-car-rental': { id: 10533 },
  '/italy-car-rental': { id: 5380 },
  '/memorial-day-car-rental': { id: 11570 }
}

// location map for setting default pickup location
// must: airport id
// DynamicPages.js
export const LocDataMap = {
  '/orlando-car-rental': {
    label: 'Orlando International Airport',
    value: 4844,
    type: 'airport'
  },
  '/los-angeles-car-rental': {
    label: 'Los Angeles International Airport',
    value: 4316,
    type: 'airport'
  },
  '/miami-car-rental': {
    label: 'Miami International Airport',
    value: 4984,
    type: 'airport'
  },
  '/fort-lauderdale-car-rental': {
    label: 'Fort Lauderdale-Hollywood International Airport',
    value: 2424,
    type: 'airport'
  },
  '/tampa-car-rental': {
    label: 'Tampa International Airport',
    value: 8050,
    type: 'airport'
  },
  '/dallas-car-rental': {
    label: 'Dallas/Fort Worth International Airport',
    value: 1807,
    type: 'airport'
  },
  '/phoenix-car-rental': {
    label: 'Phoenix Sky Harbor International Airport',
    value: 6270,
    type: 'airport'
  },
  '/tucson-car-rental': {
    label: 'Tucson International Airport',
    value: 8163,
    type: 'airport'
  },
  '/las-vegas-car-rental': {
    label: 'Las Vegas Airport',
    value: 4727,
    type: 'airport'
  },
  '/new-york-city-car-rental': {
    label: 'John F. Kennedy International Airport',
    value: 3587,
    type: 'airport'
  },
  '/brooklyn-car-rental': {
    label: 'John F. Kennedy International Airport',
    value: 3587,
    type: 'airport'
  },
  '/atlanta-car-rental': {
    label: 'Hartsfield-Jackson Atlanta International Airport',
    value: 432,
    type: 'airport'
  },
  '/san-juan-car-rental': {
    label: 'Luis Munoz Marin International Airport',
    value: 7299,
    type: 'airport'
  },
  '/chicago-car-rental': {
    label: "O'Hare International Airport",
    value: 5985,
    type: 'airport'
  },
  '/san-diego-car-rental': {
    label: 'San Diego International Airport',
    value: 7066,
    type: 'airport'
  },
  '/san-francisco-car-rental': {
    label: 'San Francisco International Airport',
    value: 7193,
    type: 'airport'
  },
  '/montauk-car-rental': {
    label: 'Montauk Airport',
    value: 5278,
    type: 'airport'
  },
  '/bronx-car-rental': {
    label: 'LaGuardia Airport',
    value: 4426,
    type: 'airport'
  },
  '/buffalo-car-rental': {
    label: 'Buffalo Niagara International Airport',
    value: 1045,
    type: 'airport'
  },
  '/niagara-falls-car-rental': {
    label: 'Niagara Falls International Airport',
    value: 3259,
    type: 'airport'
  },
  '/queens-car-rental': {
    label: 'John F. Kennedy International Airport',
    value: 3587,
    type: 'airport'
  },
  '/southampton-car-rental': {
    label: 'Long Island MacArthur Airport',
    value: 3456,
    type: 'airport'
  },
  '/jacksonville-car-rental': {
    label: 'Jacksonville International Airport',
    value: 3542,
    type: 'airport'
  },
  '/kissimmee-car-rental': {
    label: 'Kissimmee Gateway Airport',
    value: 3453,
    type: 'airport'
  },
  '/fort-myers-car-rental': {
    label: 'Southwest Florida International Airport',
    value: 6985,
    type: 'airport'
  },
  '/naples-car-rental': {
    label: 'Naples Municipal Airport',
    value: 343,
    type: 'airport'
  },
  '/malibu-car-rental': {
    label: 'Los Angeles Airport',
    value: 4661,
    type: 'airport'
  },
  '/miami-beach-car-rental': {
    label: 'Miami International Airport',
    value: 4984,
    type: 'airport'
  },
  '/st-petersburg-car-rental': {
    label: 'St. Petersburg-Clearwater International Airport',
    value: 6277,
    type: 'airport'
  },
  '/panama-city-car-rental': {
    label: 'Northwest Florida Beaches International Airport',
    value: 2081,
    type: 'airport'
  },
  '/tallahassee-car-rental': {
    label: 'Tallahassee International Airport',
    value: 7957,
    type: 'airport'
  },
  '/sacramento-car-rental': {
    label: 'Sacramento International Airport',
    value: 7363,
    type: 'airport'
  },
  '/utica-car-rental': {
    label: 'Hancock International Airport',
    value: 7682,
    type: 'airport'
  },
  '/portland-car-rental': {
    label: 'Portland International Airport',
    value: 6193,
    type: 'airport'
  },
  '/tahoe-city-car-rental': {
    label: 'Reno-Tahoe International Airport',
    value: 6921,
    type: 'airport'
  },
  '/palm-springs-car-rental': {
    label: 'Palm Springs International Airport',
    value: 6496,
    type: 'airport'
  },
  '/spring-car-rental': {
    label: 'London Heathrow Airport',
    value: 4457,
    type: 'airport'
  },
  '/italy-car-rental': {
    label: 'Leonardo da Vinci-Fiumicino Airport',
    value: 2357,
    type: 'airport'
  },
  '/memorial-day-car-rental': {
    label: 'Orlando International Airport',
    value: 4844,
    type: 'airport'
  }
}

// data map for feature cards
// DynamicPages.js
export const dataMap = {
  '/orlando-car-rental': {
    name: 'Orlando',
    secondName: 'at Disneyland',
    attr: 'the themeparks of Orlando',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Orlando-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Orlando.webp'
  },
  '/los-angeles-car-rental': {
    name: 'Los Angeles',
    secondName: 'to Santa Monica Beach',
    attr: 'the beaches and clubs in LA or to visit the walk of fame',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Los-Angeles-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-LosAngeles.webp'
  },
  '/miami-car-rental': {
    name: 'Miami',
    secondName: 'at the Port of Miami',
    attr: 'beaches of Miami',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Miami-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Miami.webp'
  },
  '/fort-lauderdale-car-rental': {
    name: 'Fort Lauderdale',
    secondName: 'at Fort Lauderdale',
    attr: 'the Everglades National Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Fort-Lauderdale-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-FortLauderdale.webp'
  },
  '/tampa-car-rental': {
    name: 'Tampa',
    secondName: 'to Tampa Bay',
    attr: 'the Tampa Bay Convention Center or Tampa Bay',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Tampa-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Tampa.webp'
  },
  '/dallas-car-rental': {
    name: 'Dallas',
    secondName: 'in Dallas',
    attr: 'the Dallas Arboretum',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Dallas-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Dallas.webp'
  },
  '/phoenix-car-rental': {
    name: 'Phoenix',
    secondName: 'in Phoenix',
    attr: 'the Desert Botanical Garden',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Phoenix-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Phoenix.webp'
  },
  '/tucson-car-rental': {
    name: 'Tucson',
    secondName: 'in Tucson',
    attr: 'the Saguaro National Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Tucson-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Tucson.webp'
  },
  '/las-vegas-car-rental': {
    name: 'Las Vegas',
    secondName: 'in Las Vegas',
    attr: 'the Red Rock Canyon National Conservation Area',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Vegas-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Vegas.webp'
  },
  '/new-york-city-car-rental': {
    name: 'NYC',
    secondName: 'in New York',
    attr: 'the Staten Island',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-NYC-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-NYC.webp'
  },
  '/brooklyn-car-rental': {
    name: 'Brooklyn',
    secondName: 'in Brooklyn',
    attr: 'the Brooklyn Botanic Gardens',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Brooklyn-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Brooklyn.webp'
  },
  '/atlanta-car-rental': {
    name: 'Atlanta',
    secondName: 'in Atlanta',
    attr: 'the Martin Luther King Jr. Historic Site',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Atlanta-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Atlanta.webp'
  },
  '/san-juan-car-rental': {
    name: 'San Juan',
    secondName: 'in San Juan, Puerto Rico',
    attr: 'the ride from Luis Muñoz Marín International Airport',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-San+Juan-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-San+Juan.webp'
  },
  '/chicago-car-rental': {
    name: 'Chicago',
    secondName: 'in Chicago',
    attr: 'the Garfield Park Conservatory',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Chicago-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Chicago.webp'
  },
  '/san-diego-car-rental': {
    name: 'San Diego',
    secondName: 'to Tampa Bay',
    attr: 'the Balboa Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-San+Diego-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-San+Diego.webp'
  },
  '/san-francisco-car-rental': {
    name: 'San Francisco',
    secondName: 'in San Francisco',
    attr: 'the Napa Valley',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-San+Francisco-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-San+Francisco.webp'
  },
  '/montauk-car-rental': {
    name: 'Montauk',
    secondName: 'in Montauk',
    attr: 'the Montauk Point Lighthouse',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Montauk-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Montauk.webp'
  },
  '/bronx-car-rental': {
    name: 'Bronx',
    secondName: 'in Bronx',
    attr: 'the Bronx Zoo',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Bronx-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Bronx.webp'
  },
  '/buffalo-car-rental': {
    name: 'Buffalo',
    secondName: 'in Buffalo',
    attr: 'the Darwin D. Martin House',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Buffalo-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Buffalo.webp'
  },
  '/niagara-falls-car-rental': {
    name: 'Niagara Falls',
    secondName: 'in Niagara Falls',
    attr: 'the Niagara Falls State Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Niagara+Falls-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Niagara+Falls.webp'
  },
  '/queens-car-rental': {
    name: 'Queens',
    secondName: 'in Queens',
    attr: 'the Citi Field',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Queens-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Queens.webp'
  },
  '/southampton-car-rental': {
    name: 'Southampton',
    secondName: 'in Southampton',
    attr: 'the Greenport',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Southampton-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Southampton.webp'
  },
  '/jacksonville-car-rental': {
    name: 'Jacksonville',
    secondName: 'in Jacksonville',
    attr: 'the Kingsley Plantation ',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Jacksonville-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Jacksonville.webp'
  },
  '/kissimmee-car-rental': {
    name: 'Kissimmee',
    secondName: 'in Kissimmee',
    attr: 'the Fun Spot America',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Kissimmee-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Kissimmee.webp'
  },
  '/fort-myers-car-rental': {
    name: 'Fort Myers',
    secondName: 'in Fort Myers',
    attr: 'the Sun Splash Waterpark',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Fort+Myers-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Fort+Myers.webp'
  },
  '/naples-car-rental': {
    name: 'Naples',
    secondName: 'in Naples',
    attr: 'the Marco Island Beaches',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Naples-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Naples.webp'
  },
  '/malibu-car-rental': {
    name: 'Malibu',
    secondName: 'in Malibu',
    attr: 'The Getty Villa',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Malibu-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Malibu.webp'
  },
  '/miami-beach-car-rental': {
    name: 'Miami Beach',
    secondName: 'in Miami Beach',
    attr: 'Art Deco Museum',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Miami-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Miami.webp'
  },
  '/st-petersburg-car-rental': {
    name: 'St. Petersburg',
    secondName: 'in St. Petersburg',
    attr: 'Salvador Dali Museum',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-St-Petersburg-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-St-Petersburg.webp'
  },
  '/panama-city-car-rental': {
    name: 'Panama City',
    secondName: 'in Panama City',
    attr: 'ZooWorld Zoological Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Panama-City-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Panama-City.webp'
  },
  '/tallahassee-car-rental': {
    name: 'Tallahassee',
    secondName: 'in Tallahassee',
    attr: 'Alfred B. Maclay Gardens State Park',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Tallahassee-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Tallahassee.webp'
  },
  '/sacramento-car-rental': {
    name: 'Sacramento',
    secondName: 'in Sacramento',
    attr: 'California Automobile Museum',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Sacramento-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Sacramento.webp'
  },
  '/utica-car-rental': {
    name: 'Utica',
    secondName: 'in Utica',
    attr: 'Munson-Williams-Proctor Arts Institute',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Utica-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Utica.webp'
  },
  '/portland-car-rental': {
    name: 'Portland',
    secondName: 'in Portland, Oregon',
    attr: 'Willamette Valley',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Portland-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Portland.webp'
  },
  '/tahoe-city-car-rental': {
    name: 'Tahoe City',
    secondName: 'in Tahoe City',
    attr: 'Lake Tahoe Dam',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Tahoe-City-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Tahoe-City.webp'
  },
  '/palm-springs-car-rental': {
    name: 'Palm Springs',
    secondName: 'in Palm Springs',
    attr: 'Palm Springs Air Museum',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-Palm-Springs-landing-banner.webp',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/Dynamic-FAQ-Image-Palm-Springs.webp'
  },
  '/spring-car-rental': {
    name: 'Spring',
    secondName: 'at any time',
    attr: 'spring',
    url: 'https://carla-web-images.s3.eu-west-1.amazonaws.com/spring-theme-background.png',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/bottom.png',
    spring: true
  },
  '/italy-car-rental': {
    name: 'Noleggio Auto in Italia',
    secondName: 'at any time',
    attr: 'Italy',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/20230517_italy_landingpage_banner.jpg',
    faqImageUrl: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/20230517_italy_landingpage_bottom.jpg',
    isItaly: true
  },
  '/memorial-day-car-rental': {
    name: 'Memorial Day',
    secondName: 'at Orlando',
    attr: 'the themeparks of Orlando',
    url: 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/20230517_Memorialday.jpg',
    faqImageUrl:
      'https://carla-landing-images.s3.eu-west-1.amazonaws.com/20230517_family-during-summer-trip-driving-by-car-2021-09-04-08-12-07-utc.jpg',
    memorial: true
  }
}

// MAP FOR BOTTOM LINKS
// BottomLinks.js
export const bottomDataMap = {
  '/orlando-car-rental': {
    name: 'Orlando',
    links1: [
      'Orlando International Airport Car Rental',
      'Orlando Sanford International Airport Car Rental',
      'Orlando Executive Airport Car Rental',
      'Melbourne Orlando International Airport Car Rental',
      'Daytona Beach International Airport Car Rental',
      'Kissimmee Gateway Airport Car Rental'
    ],
    links2: [
      'One-way car rental in Orlando',
      'Van rentals in Orlando',
      'Luxury car rentals in Orlando',
      'Economy car rentals in Orlando',
      'SUV rentals in Orlando',
      'Full-size car rentals in Orlando'
    ],
    links3: ['Downtown Orlando', 'Highland', 'Parramore', 'College Park', 'Winter Park']
  },
  '/los-angeles-car-rental': {
    name: 'Los Angeles',
    links1: [
      'Los Angeles International Airport Car Rental',
      'Hollywood Burbank Airport Car Rental',
      'Long Beach Airport Car Rental',
      'Ontario International Airport Car Rental',
      'John Wayne Airport Car Rental'
    ],
    links2: [
      'One-way car rental in Los Angeles',
      'Van rentals in Los Angeles',
      'Luxury car rentals in Los Angeles',
      'Economy car rentals in Los Angeles',
      'SUV rentals in Los Angeles',
      'Full-size car rentals in Los Angeles'
    ],
    links3: ['Downtown Los Angeles', 'Beverly Hills', 'West Hollywood', 'Venice Beach', 'Silver Lake']
  },
  '/fort-lauderdale-car-rental': {
    name: 'Fort Lauderdale',
    links1: [
      'Fort Lauderdale-Hollywood International Airport Car Rental',
      'Fort Lauderdale Executive Airport Car Rental'
    ],
    links2: [
      'One-way car rental in Fort Lauderdale',
      'Van rentals in Fort Lauderdale',
      'Luxury car rentals in Fort Lauderdale',
      'Economy car rentals in Fort Lauderdale',
      'SUV rentals in Fort Lauderdale',
      'Full-size car rentals in Fort Lauderdale'
    ],
    links3: ['Fort Lauderdale Beach', 'Las Olas Isles', 'Wilton Manors', 'Waterson Isle', 'Tarpon River']
  },
  '/tampa-car-rental': {
    name: 'Tampa',
    links1: ['Tampa International Airport Car Rental', 'St. Pete-Clearwater International Airport Car Rental'],
    links2: [
      'One-way car rental in Tampa',
      'Van rentals in Tampa',
      'Luxury car rentals in Tampa',
      'Economy car rentals in Tampa',
      'SUV rentals in Tampa',
      'Full-size car rentals in Tampa'
    ],
    links3: ['Downtown Tampa', 'Hyde Park', 'Ybor City', 'Tampa Bay Harbor']
  },
  '/miami-car-rental': {
    name: 'Miami',
    links1: ['Miami International Airport Car Rental', 'Fort Lauderdale-Hollywood International Airport Car Rental'],
    links2: [
      'One-way car rental in Miami',
      'Van rentals in Miami',
      'Luxury car rentals in Miami',
      'Economy car rentals in Miami',
      'SUV rentals in Miami',
      'Full-size car rentals in Miami'
    ],
    links3: ['Brickell', 'Miami Beach', 'Little Havana', 'Coral Gables']
  },
  '/dallas-car-rental': {
    name: 'Dallas',
    links1: [
      'Dallas/Fort Worth International Airport',
      'Dallas Love Field',
      'Dallas Executive Airport',
      'Addison Airport'
    ],
    links2: [
      'One-way car rental in Dallas',
      'Van rentals in Dallas',
      'Luxury car rentals in Dallas',
      'Economy car rentals in Dallas',
      'SUV rentals in Dallas',
      'Full-size car rentals in Dallas'
    ],
    links3: ['Downtown Dallas', 'Deep Ellum', 'Highland Park', 'Dallas Parkway']
  },
  '/phoenix-car-rental': {
    name: 'Phoenix',
    links1: ['Phoenix Sky Harbor', 'Phoenix-Mesa Gateway Airport', 'Phoenix Deer Valley Airport'],
    links2: [
      'One-way car rental in Phoenix',
      'Van rentals in Phoenix',
      'Luxury car rentals in Phoenix',
      'Economy car rentals in Phoenix',
      'SUV rentals in Phoenix',
      'Full-size car rentals in Phoenix'
    ],
    links3: ['Arcadia', 'Central Phoenix', 'Old Town Scottsdale', 'Roosevelt Row']
  },
  '/tucson-car-rental': {
    name: 'Tucson',
    links1: ['Tucson International Airport', 'Marana Regional Airport'],
    links2: [
      'One-way car rental in Tucson',
      'Van rentals in Tucson',
      'Luxury car rentals in Tucson',
      'Economy car rentals in Tucson',
      'SUV rentals in Tucson',
      'Full-size car rentals in Tucson'
    ],
    links3: ['Downtown Tucson', 'Civano', 'Dunbar Spring']
  },
  '/las-vegas-car-rental': {
    name: 'Las Vegas',
    links1: [
      'Mccarran International Airport',
      'Harry Reid International Airport',
      'North Las Vegas Airport',
      'Henderson Executive Airport'
    ],
    links2: [
      'One-way car rental in Las Vegas',
      'Van rentals in Las Vegas',
      'Luxury car rentals in Las Vegas',
      'Economy car rentals in Las Vegas',
      'SUV rentals in Las Vegas',
      'Full-size car rentals in Las Vegas'
    ],
    links3: ['Downtown Las Vegas', 'The Strip', 'North Las Vegas']
  },
  '/new-york-city-car-rental': {
    name: 'NYC',
    links1: [
      'John F. Kennedy International Airport',
      'LaGuardia Airport',
      'Newark Airport',
      'New York Stewart International Airport'
    ],
    links2: [
      'One-way car rental in NYC',
      'Van rentals in NYC',
      'Luxury car rentals in NYC',
      'Economy car rentals in NYC',
      'SUV rentals in NYC',
      'Full-size car rentals in NYC'
    ],
    links3: ['Manhattan', 'Bronx', 'Brooklyn', 'Queens']
  },
  '/brooklyn-car-rental': {
    name: 'Brooklyn',
    links1: ['John F. Kennedy International Airport', 'LaGuardia Airport'],
    links2: [
      'One-way car rental in Brooklyn',
      'Van rentals in Brooklyn',
      'Luxury car rentals in Brooklyn',
      'Economy car rentals in Brooklyn',
      'SUV rentals in Brooklyn',
      'Full-size car rentals in Brooklyn'
    ],
    links3: ['Dumbo', 'Miami Beach', 'Little Havana', 'Coral Gables']
  },
  '/atlanta-car-rental': {
    name: 'Atlanta',
    links1: ['Hartsfield-Jackson Atlanta International Airport', 'DeKalb-Peachtree Airport', 'Fulton County Airport'],
    links2: [
      'One-way car rental in Atlanta',
      'Van rentals in Atlanta',
      'Luxury car rentals in Atlanta',
      'Economy car rentals in Atlanta',
      'SUV rentals in Atlanta',
      'Full-size car rentals in Atlanta'
    ],
    links3: ['Midtown', 'College Park', 'Forest Park', 'Buckhead']
  },
  '/san-juan-car-rental': {
    name: 'San Juan',
    links1: ['Luis Muñoz Marín International Airport'],
    links2: [
      'One-way car rental in San Juan',
      'Van rentals in San Juan',
      'Luxury car rentals in San Juan',
      'Economy car rentals in San Juan',
      'SUV rentals in San Juan',
      'Full-size car rentals in San Juan'
    ],
    links3: ['Old San Juan', 'Puerta de Tierra', 'Calle Loíza', 'Isla Verde']
  },
  '/chicago-car-rental': {
    name: 'Chicago',
    links1: [
      "O'Hare International Airport",
      'Chicago Midway International Airport',
      'Chicago Rockford International Airport',
      'Chicago Executive Airport',
      'DuPage Airport'
    ],
    links2: [
      'One-way car rental in Chicago',
      'Van rentals in Chicago',
      'Luxury car rentals in Chicago',
      'Economy car rentals in Chicago',
      'SUV rentals in Chicago',
      'Full-size car rentals in Chicago'
    ],
    links3: ['The Loop', 'Navy Pier', 'River North', 'Lincoln Park']
  },
  '/san-diego-car-rental': {
    name: 'San Diego',
    links1: ['San Diego International Airport', 'Brown Field Municipal Airport', 'Montgomery-Gibbs Executive Airport'],
    links2: [
      'One-way car rental in San Diego',
      'Van rentals in San Diego',
      'Luxury car rentals in San Diego',
      'Economy car rentals in San Diego',
      'SUV rentals in San Diego',
      'Full-size car rentals in San Diego'
    ],
    links3: ['Core-Columbia', 'La Jolla', 'Coronado', 'Mission Valley']
  },
  '/san-francisco-car-rental': {
    name: 'San Francisco',
    links1: [
      'San Francisco International Airport',
      'Oakland International Airport',
      'Norman Y. Mineta San Jose International Airport'
    ],
    links2: [
      'One-way car rental in San Francisco',
      'Van rentals in San Francisco',
      'Luxury car rentals in San Francisco',
      'Economy car rentals in San Francisco',
      'SUV rentals in San Francisco',
      'Full-size car rentals in San Francisco'
    ],
    links3: ['SoMa', 'North Beach', 'Haight Ashbury', 'Nob Hill']
  },
  '/montauk-car-rental': {
    name: 'Montauk',
    links1: ['Montauk Airport', 'Long Island MacArthur Airport', 'John F. Kennedy International Airport'],
    links2: [
      'One-way car rental in Montauk',
      'Van rentals in Montauk',
      'Luxury car rentals in Montauk',
      'Economy car rentals in Montauk',
      'SUV rentals in Montauk',
      'Full-size car rentals in Montauk'
    ],
    links3: ['Montauk Village', 'Kirk Park', 'Ditch Plains']
  },
  '/bronx-car-rental': {
    name: 'Bronx',
    links1: ['LaGuardia Airport', 'John F. Kennedy International Airport', 'Newark Airport', 'White Plains Airport'],
    links2: [
      'One-way car rental in Bronx',
      'Van rentals in Bronx',
      'Luxury car rentals in Bronx',
      'Economy car rentals in Bronx',
      'SUV rentals in Bronx',
      'Full-size car rentals in Bronx'
    ],
    links3: ['Bedford Park', 'Belmont', 'Fordham', 'Highbridge']
  },
  '/buffalo-car-rental': {
    name: 'Buffalo',
    links1: [
      'Buffalo Niagara International Airport',
      'Greater Rochester International Airport',
      'Niagara Falls International Airport'
    ],
    links2: [
      'One-way car rental in Buffalo',
      'Van rentals in Buffalo',
      'Luxury car rentals in Buffalo',
      'Economy car rentals in Buffalo',
      'SUV rentals in Buffalo',
      'Full-size car rentals in Buffalo'
    ],
    links3: ['Elmwood Village', 'Allentown', 'Albright']
  },
  '/niagara-falls-car-rental': {
    name: 'Niagara Falls',
    links1: ['Niagara Falls International Airport', 'Buffalo Niagara International Airport'],
    links2: [
      'One-way car rental in Niagara Falls',
      'Van rentals in Niagara Falls',
      'Luxury car rentals in Niagara Falls',
      'Economy car rentals in Niagara Falls',
      'SUV rentals in Niagara Falls',
      'Full-size car rentals in Niagara Falls'
    ],
    links3: ['Lockport', 'Lewiston', 'North Tonawanda']
  },
  '/queens-car-rental': {
    name: 'Queens',
    links1: [
      'John F. Kennedy International Airport',
      'LaGuardia Airport',
      'Newark Liberty International Airport',
      'Westchester County Airport'
    ],
    links2: [
      'One-way car rental in Queens',
      'Van rentals in Queens',
      'Luxury car rentals in Queens',
      'Economy car rentals in Queens',
      'SUV rentals in Queens',
      'Full-size car rentals in Queens'
    ],
    links3: ['Astoria', 'Forest Hills', 'Long Island City', 'Jackson Heights']
  },
  '/southampton-car-rental': {
    name: 'Southampton',
    links1: ['Long Island MacArthur Airport', 'Tweed New Haven Airport', 'John F. Kennedy International Airport'],
    links2: [
      'One-way car rental in Southampton',
      'Van rentals in Southampton',
      'Luxury car rentals in Southampton',
      'Economy car rentals in Southampton',
      'SUV rentals in Southampton',
      'Full-size car rentals in Southampton'
    ],
    links3: ['Sag Harbor', 'Hampton Bays']
  },
  '/jacksonville-car-rental': {
    name: 'Jacksonville',
    links1: [
      'Jacksonville International Airport',
      'Gainesville Regional Airport',
      'Daytona Beach International Airport'
    ],
    links2: [
      'One-way car rental in Jacksonville',
      'Van rentals in Jacksonville',
      'Luxury car rentals in Jacksonville',
      'Economy car rentals in Jacksonville',
      'SUV rentals in Jacksonville',
      'Full-size car rentals in Jacksonville'
    ],
    links3: ['Downtown Jacksonville', 'Arlington', 'Southside', 'The Beaches']
  },
  '/kissimmee-car-rental': {
    name: 'Kissimmee',
    links1: ['Kissimmee Gateway Airport', 'Orlando International Airport', 'Orlando Sanford International Airport'],
    links2: [
      'One-way car rental in Kissimmee',
      'Van rentals in Kissimmee',
      'Luxury car rentals in Kissimmee',
      'Economy car rentals in Kissimmee',
      'SUV rentals in Kissimmee',
      'Full-size car rentals in Kissimmee'
    ],
    links3: ['Poinciana', 'Celebration', 'Intercession City']
  },
  '/fort-myers-car-rental': {
    name: 'Fort Myers',
    links1: ['Southwest Florida International Airport', 'Fort Myers Page Field'],
    links2: [
      'One-way car rental in Fort Myers',
      'Van rentals in Fort Myers',
      'Luxury car rentals in Fort Myers',
      'Economy car rentals in Fort Myers',
      'SUV rentals in Fort Myers',
      'Full-size car rentals in Fort Myers'
    ],
    links3: ['North Fort Myers', 'Sanibel', 'Fort Myers Beach', 'Iona']
  },
  '/naples-car-rental': {
    name: 'Naples',
    links1: ['Naples Municipal Airport', 'Southwest Florida International Airport'],
    links2: [
      'One-way car rental in Naples',
      'Van rentals in Naples',
      'Luxury car rentals in Naples',
      'Economy car rentals in Naples',
      'SUV rentals in Naples',
      'Full-size car rentals in Naples'
    ],
    links3: ['Park Shore', 'Pelican Bay', 'Vineyards']
  },
  '/malibu-car-rental': {
    name: 'Malibu',
    links1: ['Los Angeles Airport', 'Hollywood Burbank Airport'],
    links2: [
      'One-way car rental in Malibu',
      'Van rentals in Malibu',
      'Luxury car rentals in Malibu',
      'Economy car rentals in Malibu',
      'SUV rentals in Malibu',
      'Full-size car rentals in Malibu'
    ],
    links3: ['Eastern Malibu', 'Point Dume', 'Zuma']
  },
  '/miami-beach-car-rental': {
    name: 'Miami Beach',
    links1: ['Miami International Airport', 'Fort Lauderdale-Hollywood International Airport'],
    links2: [
      'One-way car rental in Miami Beach',
      'Van rentals in Miami Beach',
      'Luxury car rentals in Miami Beach',
      'Economy car rentals in Miami Beach',
      'SUV rentals in Miami Beach',
      'Full-size car rentals in Miami Beach'
    ],
    links3: ['Coral Gables', 'Little Havana', 'South Beach']
  },
  '/st-petersburg-car-rental': {
    name: 'St. Petersburg',
    links1: [
      'St. Petersburg-Clearwater International Airport',
      'Tampa International Airport',
      'Sarasota-Bradenton International Airport'
    ],
    links2: [
      'One-way car rental in St. Petersburg',
      'Van rentals in St. Petersburg',
      'Luxury car rentals in St. Petersburg',
      'Economy car rentals in St. Petersburg',
      'SUV rentals in St. Petersburg',
      'Full-size car rentals in St. Petersburg'
    ],
    links3: ['Historic Kendwood', 'Historic Old Northeast', 'Grand Central District']
  },
  '/panama-city-car-rental': {
    name: 'Panama City',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/tallahassee-car-rental': {
    name: 'Tallahassee',
    links1: ['Tallahassee Regional Airport', 'Valdosta Regional Airport', 'Southwest Georgia Regional Airport'],
    links2: [
      'One-way car rental in Tallahassee',
      'Van rentals in Tallahassee',
      'Luxury car rentals in Tallahassee',
      'Economy car rentals in Tallahassee',
      'SUV rentals in Tallahassee',
      'Full-size car rentals in Tallahassee'
    ],
    links3: ['Northeast Tallahassee', 'Levy Park', 'Betton Hills']
  },
  '/sacramento-car-rental': {
    name: 'Sacramento',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/utica-car-rental': {
    name: 'Utica',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/portland-car-rental': {
    name: 'Portland',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/tahoe-city-car-rental': {
    name: 'Tahoe City',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/palm-springs-car-rental': {
    name: 'Palm Springs',
    links1: ['Northwest Florida Beaches International Airport'],
    links2: [
      'One-way car rental in Panama City',
      'Van rentals in Panama City',
      'Luxury car rentals in Panama City',
      'Economy car rentals in Panama City',
      'SUV rentals in Panama City',
      'Full-size car rentals in Panama City'
    ],
    links3: ['Glenwood', 'Casco Viejo', 'Millville']
  },
  '/spring-car-rental': {
    name: 'London',
    links1: [
      'Faro Airport',
      'Orlando International Airport',
      'Malaga Airport',
      'London Heathrow Airport',
      'Palma de Mallorca Airport',
      'Valencia Airport'
    ],
    links2: ['Compact Car Hire', 'SUV Car Hire', 'Economy Car Hire', 'Full-size Car Hire'],
    links3: ['London', 'Faro', 'Malaga', 'Orlando', 'Valencia', 'Pisa', 'Dublin']
  },
  '/italy-car-rental': {
    name: 'Italy',
    links1: [
      'Aeroporto di Bologna',
      'Aeroporto di Milano-Malpensa',
      'Aeroporto di Roma-Fiumicino',
      'Aeroporto Internazionale di Napoli'
    ],
    links2: [
      'Noleggio auto economiche in Italia',
      'Noleggio auto SUV in Italia.',
      'Noleggio auto di lusso in Italia',
      'Noleggio auto di sola andata in Italia'
    ],
    links3: ['Roma', 'Napoli', 'Milano', 'Pisa', 'Venezia']
  },
  '/memorial-day-car-rental': {
    name: 'Orlando',
    links1: [
      'Orlando International Airport Car Rental',
      'Los Angeles Airport Car Rental',
      'Miami International Airport Car Rental',
      'Hartsfield-Jackson Atlanta International Airport Car Rental',
      'San Diego International Airport Car Rental'
    ],
    links2: [
      'One-way car rental',
      'Van rentals',
      'Economy car rentals',
      'SUV car rentals',
      'Full size car rentals',
      'Luxury Car Rentals'
    ],
    links3: ['Downtown Orlando', 'Highland', 'Parramore', 'College Park', 'Winter Park']
  }
}

// Location map for where to focus google map.
// center and zoom can be arbitrarily changed
// according to center where you want
// whereas id is city id and shouldn't be changed
// GoogleMapComponent.js
export const googleLocationDataMap = {
  '/orlando-car-rental': {
    center: {
      lat: 28.5711577,
      lng: -81.348083
    },
    id: 11570,
    zoom: 11
  },
  '/los-angeles-car-rental': {
    center: {
      lat: 34.0015889,
      lng: -118.30853
    },
    id: 10805,
    zoom: 11
  },
  '/fort-lauderdale-car-rental': {
    center: {
      lat: 26.109412,
      lng: -80.343591
    },
    id: 11943,
    zoom: 11
  },
  '/tampa-car-rental': {
    center: {
      lat: 27.979168,
      lng: -82.539337
    },
    id: 11012,
    zoom: 10
  },
  '/miami-car-rental': {
    center: {
      lat: 25.7433,
      lng: -80.2506
    },
    id: 13036,
    zoom: 11
  },
  '/dallas-car-rental': {
    center: {
      lat: 32.72748,
      lng: -96.940443
    },
    id: 9689,
    zoom: 9
  },
  '/phoenix-car-rental': {
    center: {
      lat: 33.607269,
      lng: -112.007788
    },
    id: 11563,
    zoom: 10
  },
  '/tucson-car-rental': {
    center: {
      lat: 32.216112,
      lng: -110.941109
    },
    id: 10073,
    zoom: 10
  },
  '/las-vegas-car-rental': {
    center: {
      lat: 36.06601,
      lng: -115.153969
    },
    id: 12655,
    zoom: 10
  },
  '/new-york-city-car-rental': {
    center: {
      lat: 40.701766,
      lng: -73.880968
    },
    id: 19128,
    zoom: 11
  },
  '/brooklyn-car-rental': {
    center: {
      lat: 40.631766,
      lng: -73.9580968
    },
    id: 19008,
    zoom: 11
  },
  '/atlanta-car-rental': {
    center: {
      lat: 33.785411,
      lng: -84.319853
    },
    id: 10020,
    zoom: 11
  },
  '/san-juan-car-rental': {
    center: {
      lat: 18.4075,
      lng: -66.1054
    },
    id: 16480,
    zoom: 12
  },
  '/chicago-car-rental': {
    center: {
      lat: 41.768611,
      lng: -87.654724
    },
    id: 11487,
    zoom: 10
  },
  '/san-diego-car-rental': {
    center: {
      lat: 32.802346,
      lng: -117.006053
    },
    id: 14755,
    zoom: 10
  },
  '/san-francisco-car-rental': {
    center: {
      lat: 37.765223,
      lng: -122.389977
    },
    id: 11705,
    zoom: 12
  },
  '/montauk-car-rental': {
    center: {
      lat: 41.05,
      lng: -71.9395
    },
    id: 14645,
    zoom: 13
  },
  '/bronx-car-rental': {
    center: {
      lat: 40.7769271,
      lng: -73.8739659
    },
    id: 19029,
    zoom: 10
  },
  '/buffalo-car-rental': {
    center: {
      lat: 42.88406,
      lng: -78.72965
    },
    id: 10626,
    zoom: 9
  },
  '/niagara-falls-car-rental': {
    center: {
      lat: 43.108031,
      lng: -78.946693
    },
    id: 12745,
    zoom: 11
  },
  '/queens-car-rental': {
    center: {
      lat: 40.721766,
      lng: -73.7980968
    },
    id: 19128,
    zoom: 13
  },
  '/southampton-car-rental': {
    center: {
      lat: 40.9972401,
      lng: -72.453215
    },
    id: 12677,
    zoom: 10
  },
  '/jacksonville-car-rental': {
    center: {
      lat: 30.2508333333,
      lng: -81.6333333333
    },
    id: 11122,
    zoom: 10
  },
  '/kissimmee-car-rental': {
    center: {
      lat: 28.261956,
      lng: -81.40757
    },
    id: 12934,
    zoom: 11
  },
  '/fort-myers-car-rental': {
    center: {
      lat: 26.535,
      lng: -81.9532
    },
    id: 11965,
    zoom: 10
  },
  '/naples-car-rental': {
    center: {
      lat: 26.1515,
      lng: -81.7718
    },
    id: 9931,
    zoom: 11
  },
  '/malibu-car-rental': {
    center: {
      lat: 34.1714271,
      lng: -118.9105877
    },
    id: 13167,
    zoom: 11
  },
  '/miami-beach-car-rental': {
    center: {
      lat: 25.7929198,
      lng: -80.1353006
    },
    id: 19004,
    zoom: 11
  },
  '/st-petersburg-car-rental': {
    center: {
      lat: 27.7703796,
      lng: -82.6695085
    },
    id: 15577,
    zoom: 11
  },
  '/panama-city-car-rental': {
    center: {
      lat: 30.1600827,
      lng: -85.6545729
    },
    id: 11616,
    zoom: 11
  },
  '/tallahassee-car-rental': {
    center: {
      lat: 30.4380832,
      lng: -84.2809332
    },
    id: 17135,
    zoom: 11
  },
  '/sacramento-car-rental': {
    center: {
      lat: 38.5810606,
      lng: -121.4938951
    },
    id: 14233,
    zoom: 11
  },
  '/utica-car-rental': {
    center: {
      lat: 43.1009031,
      lng: -75.2326641
    },
    id: 17393,
    zoom: 11
  },
  '/portland-car-rental': {
    center: {
      lat: 45.5202471,
      lng: -122.6741949
    },
    id: 15480,
    zoom: 11
  },
  '/tahoe-city-car-rental': {
    center: {
      lat: 39.327962,
      lng: -120.1832533
    },
    id: 17107,
    zoom: 11
  },
  '/palm-springs-car-rental': {
    center: {
      lat: 33.8246269,
      lng: -116.540303
    },
    id: 15768,
    zoom: 11
  },
  '/spring-car-rental': {
    center: {
      lat: 51.481147,
      lng: -0.430723
    },
    id: 10533,
    zoom: 11
  },
  '/italy-car-rental': {
    center: {
      lat: 41.902782,
      lng: 12.496366
    },
    id: 2357,
    zoom: 11
  },
  '/memorial-day-car-rental': {
    center: {
      lat: 28.5711577,
      lng: -81.348083
    },
    id: 11570,
    zoom: 11
  }
}
