import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import API from '../../services/API'
import util from '../../util'
import GA from '../../services/GA'
import EventSender from '../../services/EventSender'
import { withRouter } from 'react-router-dom'
import {
  setSearchDropOffLocation,
  setSearchId,
  setPromoCode,
  setSearchPickupLocation,
  setSearchArgs,
  setCarRentalProduct,
  setSkyScanner,
  setRedirectURL
} from '../../redux/slices'
import { connect } from 'react-redux'
import ErrorHandler from '@base/services/ErrorHandler'

import Loadable from '@base/loadable'
const LoadingScreen = Loadable(React.lazy(() => import('@components/CarListing/LoadingScreen')))

const RedirectComponent = ({ setSearchArgs, setCarRentalProduct, setSkyScanner, setRedirectURL }) => {
  const history = useHistory()
  const { departdatetime, returndatetime, pickup, dropoff, driverAge, carId, carType } = useParams()

  const carFilterParam = carType ? `?carType=${carType}` : ''

  useEffect(() => {
    setRedirectURL(`/rental-cars/${pickup}/${dropoff}/${departdatetime}/${returndatetime}${carFilterParam}`)
  }, [departdatetime, dropoff, pickup, returndatetime, setRedirectURL, carFilterParam])

  // TODO: refactor
  useEffect(() => {
    async function formatCarRentalProductForXO1() {
      const timeout = 3000

      const apiCallPromise = API.getReservationDetailForRedirectedXO1(carId)

      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout error'))
        }, timeout)
      })

      try {
        const data = await Promise.race([apiCallPromise, timeoutPromise])
        const pickupLocation = await API.getPlace(pickup)
        const dropoffLocation = await API.getPlace(dropoff)

        const { carRentalProduct, freeCancellationInfo } = data // backend added freeCancel field separately for ease of implementation
        const carRentalProductCopy = { ...carRentalProduct, freeCancellationInfo } // spreading carRentalProduct to avoid  breaking existing logic
        carRentalProductCopy.searchId = carRentalProduct.dependencies.searchId + ''

        carRentalProductCopy.searchArgs = {
          age: driverAge >= 26 ? 26 : driverAge,
          dropOffDateStr: returndatetime,
          dropOffDateTime: util.getDateFromAPI(returndatetime),
          dropOffLocation: dropoff,
          dropOffLocationName: dropoffLocation.prettyName,
          pickupDateStr: departdatetime,
          pickupDateTime: util.getDateFromAPI(departdatetime),
          pickupLocation: pickup,
          pickupLocationName: pickupLocation.prettyName,
          searchId: carRentalProduct.dependencies.searchId + ''
        }

        setSearchArgs({
          pickupLocation: {
            value: pickup,
            label: pickupLocation.prettyName
          },
          dropOffLocation: {
            value: dropoff,
            label: dropoffLocation.prettyName
          },
          pickupDateStr: util.getDateFromAPI(departdatetime).toString(),
          dropOffDateStr: util.getDateFromAPI(returndatetime).toString(),
          age: driverAge >= 26 ? 26 : driverAge,
          pickupTime: util.getTimeObjFromAPI(departdatetime),
          dropOffTime: util.getTimeObjFromAPI(returndatetime),
          pickupDate: util.getDateFromAPI(departdatetime),
          dropOffDate: util.getDateFromAPI(returndatetime),
          pickupDateTime: util.getDateFromAPI(departdatetime),
          dropOffDateTime: util.getDateFromAPI(returndatetime),
          isSameLocation: pickup === dropoff
        })

        API.getSelectedCar(carRentalProductCopy).then((data) => {
          if (data) {
            localStorage.setItem('productID', JSON.stringify(data))
          }
        })

        GA.selectCar(0)

        EventSender.carSelected(carRentalProductCopy)
        setCarRentalProduct(carRentalProductCopy)

        API.sendPreConversionEvent('DAY_VIEW')
        setSkyScanner(true)
        history.push(`/rental-cars/${pickup}/${dropoff}/${departdatetime}/${returndatetime}${carFilterParam}`)
        history.push('/reservation')
      } catch (error) {
        ErrorHandler.handleError(error)
        API.sendPreConversionEvent('HOME_PAGE')
        history.push(`/rental-cars/${pickup}/${dropoff}/${departdatetime}/${returndatetime}${carFilterParam}`)
      }
    }

    formatCarRentalProductForXO1()
  }, [
    carId,
    departdatetime,
    driverAge,
    dropoff,
    history,
    pickup,
    returndatetime,
    carType,
    carFilterParam,
    setCarRentalProduct,
    setSearchArgs,
    setSkyScanner
  ])

  return <LoadingScreen />
}

const ConnectedRedirect = withRouter(
  connect(
    (state) => ({
      carRentalProduct: state.carRentalProduct
    }),
    {
      setSearchArgs,
      setSearchPickupLocation,
      setSearchDropOffLocation,
      setCarRentalProduct,
      setSearchId,
      setPromoCode,
      setSkyScanner,
      setRedirectURL
    }
  )(RedirectComponent)
)

export default ConnectedRedirect
