import { createSlice } from '@reduxjs/toolkit'
import getTime from 'date-fns/getTime'

const initialState = {
  pickupLocation: null, // {value,label}
  dropOffLocation: null, // {value,label}
  pickupDateStr: null, // ISO string
  dropOffDateStr: null, // ISO string
  pickupTime: { hour: 12, min: 0 },
  dropOffTime: { hour: 12, min: 0 },
  age: 26, // integer
  promoCode: null,
  isSameLocation: true
}

export const searchArgsSlice = createSlice({
  name: 'searchArgsSlice',
  initialState,
  reducers: {
    setSearchArgs: (state, action) => {
      state = {
        ...state,
        ...action.payload
      }
      return state
    },
    updateSearchDateRange: (state, action) => {
      state.pickupDateStr = action.payload.startDate
      state.dropOffDateStr = action.payload.endDate
    },
    clearSearchDateRange: (state) => {
      state.pickupDateStr = null
      state.dropOffDateStr = null
    },
    setSearchPickupDate: (state, action) => {
      state.pickupDateStr = action.payload.toString()
    },
    setSearchDropOffDate: (state, action) => {
      state.dropOffDateStr = action.payload.toString()
    },
    setSearchPickupTime: (state, action) => {
      state.pickupTime = action.payload
      const { hour = 12, min = 0 } = action.payload
      state.pickupDateStr = getTime(new Date(state.pickupDateStr).setHours(hour, min))
    },
    setSearchDropOffTime: (state, action) => {
      state.dropOffTime = action.payload
      const { hour = 12, min = 0 } = action.payload
      state.dropOffDateStr = getTime(new Date(state.dropOffDateStr).setHours(hour, min))
    },
    setSearchPickupLocation: (state, action) => {
      if (state.isSameLocation) {
        state.pickupLocation = action.payload
        state.dropOffLocation = action.payload
      } else {
        state.pickupLocation = action.payload
      }
    },
    setSearchDropOffLocation: (state, action) => {
      state.dropOffLocation = action.payload
    },
    setSearchAge: (state, action) => {
      state.age = action.payload
    },
    setSearchId: (state, action) => {
      state.searchId = action.payload
    },
    setPromoCode: (state, action) => {
      state.promoCode = action.payload
    },
    setSameLocation: (state, action) => {
      if (action.payload) {
        state.dropOffLocation = state.pickupLocation
      }
      state.isSameLocation = action.payload
      return state
    },
    toggleSameLocation: (state, action) => {
      if (!state.isSameLocation) {
        state.dropOffLocation = state.pickupLocation
      }
      state.isSameLocation = !state.isSameLocation
      return state
    }
  }
})

export const {
  setSearchArgs,
  updateSearchDateRange,
  clearSearchDateRange,
  setSearchPickupDate,
  setSearchDropOffDate,
  setSearchPickupTime,
  setSearchDropOffTime,
  setSearchPickupLocation,
  setSearchDropOffLocation,
  setSearchAge,
  setSearchId,
  setPromoCode,
  setSameLocation,
  toggleSameLocation
} = searchArgsSlice.actions

export default searchArgsSlice.reducer
