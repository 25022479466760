import i18Middleware from 'i18next-http-middleware'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'

import translationAR from './src/locales/ar/translation.json'
import translationDE from './src/locales/de/translation.json'
import translationEN from './src/locales/en/translation.json'
import translationES from './src/locales/es/translation.json'
import translationFR from './src/locales/fr/translation.json'
import translationIT from './src/locales/it/translation.json'
import translationPT from './src/locales/pt/translation.json'
import translationTR from './src/locales/tr/translation.json'
import translationZH from './src/locales/zh/translation.json'
import translationRU from './src/locales/ru/translation.json'
import translationUK from './src/locales/uk/translation.json'

const i18Resources = {
  en: translationEN,
  ar: translationAR,
  de: translationDE,
  es: translationES,
  fr: translationFR,
  it: translationIT,
  pt: translationPT,
  tr: translationTR,
  zh: translationZH,
  ru: translationRU,
  uk: translationUK
}

i18next
  .use(Backend)
  .use(i18Middleware.LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'all',
    fallbackLng: ['en'],
    preload: ['en'],
    resources: i18Resources,
    detection: {
      caches: ['cookie']
    }
  })

export { i18next, i18Middleware, translationEN, I18nextProvider, i18Resources }
