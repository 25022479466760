import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const carRentalProductSlice = createSlice({
  name: 'carRentalProductSlice',
  initialState,
  reducers: {
    setCarRentalProduct: (state, action) => {
      state = action.payload
      return state
    },
    updateCarRentalProduct: (state, action) => {
      state = {
        ...state,
        ...action.payload
      }
      return state
    },
    removeCarRentalProduct: (state) => {
      state = initialState
      return state
    }
  }
})

export const { setCarRentalProduct, updateCarRentalProduct, removeCarRentalProduct } = carRentalProductSlice.actions

export default carRentalProductSlice.reducer
