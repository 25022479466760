import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  error: null,
  items: null
}
// TODO: remove and migrate to React-query/RTK
export const locationSearchSlice = createSlice({
  name: 'locationSearch',
  initialState,
  reducers: {
    startLocationSearch: (state) => {
      state.isLoading = true
      state.error = null
      state.items = []
      return state
    },
    setLocationSearchItems: (state, action) => {
      state = {
        isLoading: false,
        error: null,
        items: action.payload
      }
      return state
    }
  }
})

export const { startLocationSearch, setLocationSearchItems } = locationSearchSlice.actions

export default locationSearchSlice.reducer
