import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: ''
}

export const userDetailsSlice = createSlice({
  name: 'userDetailsSlice',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state = action.payload
      return state
    }
  }
})

export const { setUserDetails } = userDetailsSlice.actions

export default userDetailsSlice.reducer
