import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@mui/material/styles'

export const DesktopScreen = ({ children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.values.lg })
  return isDesktop ? children : null
}

export const TabletScreen = ({ children }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery({ minWidth: theme.breakpoints.values.sm, maxWidth: theme.breakpoints.values.lg - 1 })
  return isTablet ? children : null
}

export const OverMobileScreen = ({ children, customBreakpoint = undefined }) => {
  const theme = useTheme()
  const isActive = useMediaQuery({ minWidth: customBreakpoint || theme.breakpoints.values.sm })
  return isActive ? children : null
}

export const MobileScreen = ({ children, customBreakpoint = undefined }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery({ maxWidth: customBreakpoint - 1 || theme.breakpoints.values.sm - 1 })
  return isMobile ? children : null
}

export const DefaultScreen = ({ children }) => {
  const theme = useTheme()
  const isDefault = useMediaQuery({ maxWidth: theme.breakpoints.values.lg - 1 })
  return isDefault ? children : null
}

export const useScreen = () => {
  const theme = useTheme()
  const isDefaultScreen = useMediaQuery({ maxWidth: theme.breakpoints.values.lg - 1 })
  const isMobileScreen = useMediaQuery({ maxWidth: theme.breakpoints.values.md - 1 })

  return {
    isDefaultScreen,
    isMobileScreen
  }
}

export const useBreakpoint = (maxWidth = 600) => {
  const isMobileScreen = useMediaQuery({ maxWidth: maxWidth })

  return isMobileScreen
}
