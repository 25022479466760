import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  response: null,
  reservationId: null,
  type: null,
  carSearchArgs: null,
  product: null,
  userInfo: null,
  modificationPriceDifference: null,
  paymentOption: null
}

export const modificationSlice = createSlice({
  name: 'modificationSlice',
  initialState,
  reducers: {
    setModificationResponse: (state, action) => {
      state.response = action.payload
    },
    setModificationArgs: (state, action) => {
      const {
        reservationId,
        type,
        carSearchArgs,
        product,
        userInfo,
        modificationPriceDifference,
        paymentOption
      } = action.payload
      state.reservationId = reservationId ?? state.reservationId
      state.type = type ?? state.type
      state.carSearchArgs = carSearchArgs ?? state.carSearchArgs
      state.product = product ?? state.product
      state.userInfo = userInfo ?? state.userInfo
      state.modificationPriceDifference = modificationPriceDifference ?? state.modificationPriceDifference
      state.paymentOption = paymentOption ?? state.paymentOption
    },
    reset: () => initialState
  }
})

export const { setModificationResponse, setModificationArgs, reset } = modificationSlice.actions

export default modificationSlice.reducer
