import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bookingHotel: null,
  bookingHotelId: null,
  searchId: null,
  location: null,
  dateRange: {
    checkInDate: null,
    checkOutDate: null
  },
  locationsSearch: {
    isLoading: false,
    error: null,
    items: []
  }
}

export const hotelSlice = createSlice({
  name: 'hotelBooking',
  initialState,
  reducers: {
    setBookingHotel: (state, action) => {
      state.bookingHotel = action.payload
    },
    setBookingHotelId: (state, action) => {
      state.bookingHotelId = action.payload
    },
    removeBookingHotel: (state) => {
      state.bookingHotel = null
      state.bookingHotelId = null
    },
    setHotelSearchId: (state, action) => {
      state.searchId = action.payload
    },
    setHotelBookingLocation: (state, action) => {
      state.location = action.payload
    },
    setHotelBookingDateRange: (state, action) => {
      const { startDate, endDate } = action.payload
      state.dateRange = {
        checkInDate: startDate,
        checkOutDate: endDate
      }
    },
    removeHotelBookingDateRange: (state) => {
      state.dateRange = initialState.dateRange
    },
    startHotelLocationsSearch: (state) => {
      state.locationsSearch = {
        isLoading: true,
        error: null,
        items: []
      }
    },
    setHotelLocationsSearch: (state, action) => {
      state.locationsSearch = {
        isLoading: false,
        error: null,
        items: action.payload
      }
    }
  }
})

export const {
  setBookingHotel,
  setBookingHotelId,
  removeBookingHotel,
  setHotelSearchId,
  setHotelBookingLocation,
  setHotelBookingDateRange,
  removeHotelBookingDateRange,
  startHotelLocationsSearch,
  setHotelLocationsSearch
} = hotelSlice.actions

export default hotelSlice.reducer
