import Loadable from '@base/loadable'
import GA from '@services/GA'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OutlineButton } from './../navigation.styles'

const DownloadApp = Loadable(React.lazy(() => import('./../DownloadApp')))

export default function DownloadAppLink() {
  const [showQR, setShowQR] = useState(false)
  const [shouldPersist, setShouldPersist] = useState(false)
  const [codeSize, setCodeSize] = useState(72)
  const [first, setFirst] = useState(true)
  const [leaving, setLeaving] = useState(false)
  const { t } = useTranslation()

  const handleEnter = () => {
    setShowQR(true)
    if (first) {
      setTimeout(() => {
        setCodeSize(144)
        setTimeout(() => {
          setCodeSize(72)
        }, 1000)
      }, 1500)
      setFirst(false)
    }
  }

  const handleClick = () => {
    GA.clickDownloadApp()
    setShouldPersist(!shouldPersist)
  }

  return (
    <>
      <OutlineButton
        rel='noopener noreferrer'
        onMouseEnter={handleEnter}
        onMouseLeave={() => {
          if (!shouldPersist) {
            setLeaving(true)
          }
          setShowQR(false)
          setTimeout(() => {
            setLeaving(false)
          }, 500)
        }}
        onClick={handleClick}
        mx={24}
      >
        {t('homePage:downloadApp')}
      </OutlineButton>
      {showQR && (
        <DownloadApp
          showQR={showQR}
          shouldPersist={shouldPersist}
          codeSize={codeSize}
          leaving={leaving}
          setShowQR={setShowQR}
          setCodeSize={setCodeSize}
        />
      )}
    </>
  )
}
