import { Suspense, lazy, useEffect, useState } from 'react'

const LazyWithoutSSR = (props) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    <Suspense fallback={<></>}>
      <props.component {...props} />
    </Suspense>
  )
}

const LazyWithSSR = (props) => (
  <Suspense fallback={<></>}>
    <props.component {...props} />
  </Suspense>
)

type LoadableOptions = {
  ssr?: boolean
}

const Loadable =
  (Component: unknown, { ssr }: LoadableOptions = { ssr: false }) =>
  (props) =>
    ssr ? <LazyWithSSR {...props} component={Component} /> : <LazyWithoutSSR {...props} component={Component} />

// eslint-disable-next-line react-refresh/only-export-components
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      return await componentImport()
    } catch (error) {
      console.error(error)
      return window.location.reload()
    }
  })

export default Loadable
