import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const reservationResponseSlice = createSlice({
  name: 'reservationResponseSlice',
  initialState,
  reducers: {
    setReservationResponse: (state, action) => {
      state = action.payload
      return state
    },
    removeReservationResponse: (state) => {
      state = initialState
      return state
    }
  }
})

export const { setReservationResponse, removeReservationResponse } = reservationResponseSlice.actions

export default reservationResponseSlice.reducer
