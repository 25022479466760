import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const HIDDEN_BY_LANGS = ['it', 'es', 'de', 'fr', 'pt', 'tr'] as const

// Hidden something if lang in hidden list
// This is temporary. When full localization is achieved, this should be removed
export const useHiddenByLang = function (langs = HIDDEN_BY_LANGS) {
  const [hidden, setHidden] = useState(false)
  const language = useSelector((state) => state.user.language || 'en')

  useEffect(() => {
    setHidden(langs.includes(language))
  }, [langs, language])

  return hidden
}
