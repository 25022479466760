import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ancillaryProducts: {
    data: null,
    error: null,
    loading: true
  },
  selectedAncillaries: []
}

export const ancillaryProducts = createSlice({
  name: 'ancillaryProducts',
  initialState,
  reducers: {
    setAncillaryProducts: (state, action) => {
      state.ancillaryProducts = action.payload
      return state
    },
    setSelectedAncillaries: (state, action) => {
      state.selectedAncillaries = action.payload
      return state
    }
  }
})

export const { setAncillaryProducts, setSelectedAncillaries } = ancillaryProducts.actions

export default ancillaryProducts.reducer
