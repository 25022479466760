import { init as initSentry, withScope, captureException } from '@sentry/react'
import util from '../util'

/**
 * initialize sentry if running in browser
 */
export const init = () => {
  if (util.isLocal()) return

  const dsn = 'https://4e64a27c592b4490aac79f477fac3ccf@o128531.ingest.us.sentry.io/1372680'

  let environment: 'development' | 'production'
  if (util.isDevelopment()) environment = 'development'
  if (util.isProduction()) environment = 'production'

  const release = import.meta.env.VITE_CARLA_VERSION

  initSentry({ dsn, environment, debug: util.isDevelopment(), release })
}

/**
 * pack error captured in render method by componentDidCatch
 */
export const handleReactError = (error, errorInfo) => {
  if (util.isLocal()) return
  withScope((scope) => {
    for (const key in errorInfo) {
      scope.setExtra(key, errorInfo[key])
    }
    captureException(error)
  })
}

/**
 * send error to sentry
 */
export const handleError = (error) => {
  if (util.isLocal()) return
  captureException(error)
}

export default {
  init,
  handleError,
  handleReactError
}
