import { createSlice } from '@reduxjs/toolkit'

const MainBundleTypes = {
  HOTEL: 'HOTEL',
  CAR: 'CAR'
}

const initialState = {
  mode: MainBundleTypes.HOTEL,
  isMatchCriteria: true,
  searchId: null,
  location: null,
  dateRange: {
    checkInDate: null,
    checkOutDate: null
  },
  locationsSearch: {
    isLoading: false,
    error: null,
    items: []
  },
  bundleId: null,
  bundleSelected: {
    hotelProduct: {},
    carProduct: {},
    bundlePrice: {}
  },
  checkoutParams: {
    carRentalId: null,
    hotelProductId: null,
    threeDSResponse: null,
    paymentOption: null,
    isCarlaPlusSelected: null
  }
}

export const bundleSlice = createSlice({
  name: 'bundleBooking',
  initialState,
  reducers: {
    toggleMatchCriteria: (state) => {
      state.isMatchCriteria = !state.isMatchCriteria
    },
    setBundleSearchId: (state, action) => {
      state.searchId = action.payload
    },
    setBundleId: (state, action) => {
      state.bundleId = action.payload
    },
    setBundleBookingLocation: (state, action) => {
      state.location = action.payload
    },
    setBundleBookingDateRange: (state, action) => {
      const { startDate, endDate } = action.payload
      state.dateRange = {
        checkInDate: startDate,
        checkOutDate: endDate
      }
    },
    startBundleLocationsSearch: (state) => {
      state.locationsSearch = {
        isLoading: true,
        error: null,
        items: []
      }
    },
    setBundleLocationsSearch: (state, action) => {
      state.locationsSearch = {
        isLoading: false,
        error: null,
        items: action.payload
      }
    },
    setBookingBundle: (state, action) => {
      state.bundleSelected = action.payload
    },
    setCheckoutParams: (state, action) => {
      state.checkoutParams = action.payload
    },
    setCarlaPlus: (state, action) => {
      state.checkoutParams.isCarlaPlusSelected = action.payload
    }
  }
})

export const {
  toggleMatchCriteria,
  setBundleSearchId,
  setBundleId,
  setBundleBookingLocation,
  setBundleBookingDateRange,
  startBundleLocationsSearch,
  setBundleLocationsSearch,
  setBookingBundle,
  setCheckoutParams,
  setCarlaPlus
} = bundleSlice.actions

export default bundleSlice.reducer
