import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null, // TODO: why error here
  response: null,
  cancellationTerms: null,
  cancelOption: null
}

export const reservationInfoRetrievalSlice = createSlice({
  name: 'reservationInfoRetrievalSlice',
  initialState,
  reducers: {
    setReservationInfoResponse: (state, action) => {
      state.response = action.payload
    },
    removeReservationInfoResponse: state => {
      state = initialState
      return state
    },
    setCancellationTerms: (state, action) => {
      state.cancellationTerms = action.payload
    },
    setCancelOption: (state, action) => {
      state.cancelOption = action.payload
    }
  }
})

export const {
  setReservationInfoResponse,
  removeReservationInfoResponse,
  setCancellationTerms,
  setCancelOption
} = reservationInfoRetrievalSlice.actions

export default reservationInfoRetrievalSlice.reducer
