import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showFooter: true
}

//TODO: need to be remove
export const footerSlice = createSlice({
  name: 'footerSlice',
  initialState,
  reducers: {
    showFooter: (state) => {
      state.showFooter = true
    },
    hideFooter: (state) => {
      state.showFooter = false
    }
  }
})

export const { showFooter, hideFooter } = footerSlice.actions

export default footerSlice.reducer
