import { useEffect, useState } from 'react'

export function usePined(elementId = '#main-header') {
  const [pinned, setPinned] = useState(false)

  useEffect(() => {
    const headerEl = document.querySelector(elementId)
    const observer = new IntersectionObserver(([e]) => (e.intersectionRatio < 1 ? setPinned(true) : setPinned(false)), {
      threshold: [1]
    })

    observer.observe(headerEl)
  }, [elementId])

  return pinned
}
